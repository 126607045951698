import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store, { DocumentStore } from "@/store";
import {
  Operation,
  OperationType,
  TaskDto,
  TasksClient,
  TaskStatus,
} from "@/api-client";
import AsyncLock from "async-lock";
import Vue from "vue";

@Module({
  name: "tasks",
  namespaced: true,
  store,
})
export default class TasksModule extends VuexModule {
  public searchText = null as string | null | undefined;
  public hasNextPage = true;
  public getTasksInProgress = false;
  public tasks = [] as TaskDto[];
  public category = 0 as number;
  private pageNumber = 1;
  private pageSize = 15;
  private tasksClient = new TasksClient();
  private tasksLoadingLock = "";
  private asyncLock = new AsyncLock();
  private changeTaskStatusAsyncLock = new AsyncLock();
  public search = false;

  @Mutation
  public switchSearch() {
    this.search = !this.search;
  }

  @Mutation
  public setSearch(search: boolean) {
    this.search = search;
  }

  @Mutation
  public addTask(task: TaskDto): void {
    this.tasks.push(task);
  }

  @Mutation
  public resetTasks(): void {
    this.tasks = [];
  }

  @Mutation
  public removeTask(id: string): void {
    const taskPosition = this.tasks.map((x) => x.id).indexOf(id);
    this.tasks.splice(taskPosition, 1);
  }

  @Mutation
  public changeTaskStatusToCompleted(id: string): void {
    const taskPosition = this.tasks.map((x) => x.id).indexOf(id);
    const task = this.tasks[taskPosition];
    task.status = TaskStatus.Completed;
    Vue.set(this.tasks, taskPosition, task);
  }

  @Mutation
  public changeTaskStatusToUncompleted(id: string): void {
    const taskPosition = this.tasks.map((x) => x.id).indexOf(id);
    const task = this.tasks[taskPosition];
    task.status = task.overdue
      ? TaskStatus.Overdue
      : task.viewed
      ? TaskStatus.InProgress
      : TaskStatus.New;
    Vue.set(this.tasks, taskPosition, task);
  }

  @Mutation
  public setSearchText(searchText: string | null | undefined) {
    this.searchText = searchText;
  }

  @Mutation
  public setCategory(category: number) {
    this.category = category;
  }

  @Mutation
  public resetPageNumber() {
    this.pageNumber = 1;
  }

  @Mutation
  public incrementPageNumber() {
    this.pageNumber++;
  }

  @Mutation
  public setHasNextPage(hasNextPage: boolean) {
    this.hasNextPage = hasNextPage;
  }

  @Mutation
  public setGetTasksInProgress(getTasksInProgress: boolean): void {
    this.getTasksInProgress = getTasksInProgress;
  }

  @Action
  public async resetAndLoadMoreTasks() {
    this.resetPageNumber();
    this.setHasNextPage(true);
    await this.loadMoreTasks();
  }

  @Action
  public async loadMoreTasks() {
    await this.asyncLock.acquire(this.tasksLoadingLock, async () => {
      if (!this.hasNextPage) {
        return;
      }

      this.setGetTasksInProgress(true);

      const pageNumber = this.pageNumber;
      this.incrementPageNumber();

      const tasks = await this.tasksClient.getWithPagination(
        null,
        null,
        this.searchText,
        this.category == 0 ? null : false,
        this.category == 0 ? null : false,
        this.category == 0 ? null : false,
        null,
        null,
        ["date:desc", "created:desc"],
        pageNumber,
        this.pageSize
      );

      if (pageNumber == 1) {
        this.resetTasks();
      }

      for (const newTask of tasks.items) {
        this.addTask(newTask);
      }

      this.setHasNextPage(tasks.hasNextPage);
      this.setGetTasksInProgress(false);
    });
  }

  @Action
  public async setTaskCompleted(taskId: string) {
    if (this.category == 1) {
      this.removeTask(taskId);
    } else {
      this.changeTaskStatusToCompleted(taskId);
    }

    await this.changeTaskStatusAsyncLock.acquire(taskId, async () => {
      await this.tasksClient.patch(taskId, [
        new Operation({
          op: OperationType.Replace,
          path: "completed",
          value: true,
        }),
      ]);
    });

    DocumentStore.resetTasks();
  }

  @Action
  public async setTaskUnCompleted(taskId: string) {
    this.changeTaskStatusToUncompleted(taskId);

    await this.changeTaskStatusAsyncLock.acquire(taskId, async () => {
      await this.tasksClient.patch(taskId, [
        new Operation({
          op: OperationType.Replace,
          path: "completed",
          value: false,
        }),
      ]);
    });

    DocumentStore.resetTasks();
  }
}
