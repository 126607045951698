import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store, { DocumentStore, ErrorsStore } from "@/store";
import {
  TasksClient,
  UpdateTaskCommand,
  ValidationProblemDetails,
} from "@/api-client";
import ErrorsFromException from "@/types/errorsFromException";
import i18n from "@/i18n";
import moment from "moment";
import router from "@/router";

@Module({
  name: "taskEdit",
  namespaced: true,
  store,
})
export default class TaskEditModule extends VuexModule {
  public getTaskInProgress = false;
  public taskLoaded = false;
  public documentLoaded = false;
  public taskDeletingInProgress = false;
  public updateTaskCommand = new UpdateTaskCommand();
  public taskUpdatingInProgress = false;
  private tasksClient = new TasksClient();

  @Mutation
  public setTaskUpdatingInProgress(taskUpdatingInProgress: boolean) {
    this.taskUpdatingInProgress = taskUpdatingInProgress;
  }

  @Mutation
  public setId(id: string) {
    this.updateTaskCommand.id = id;
  }

  @Mutation
  public setName(name: string) {
    this.updateTaskCommand.name = name;
  }

  @Mutation
  public setDateTime(dateTime: string | undefined) {
    this.updateTaskCommand.dateTime = dateTime;
  }

  @Mutation
  public setDescription(description: string | undefined) {
    this.updateTaskCommand.description = description;
  }

  @Mutation
  public setDocumentId(documentId: string | undefined) {
    this.updateTaskCommand.documentId = documentId;
  }

  @Mutation
  public setGetTaskInProgress(getTaskInProgress: boolean): void {
    this.getTaskInProgress = getTaskInProgress;
  }

  @Mutation
  public setUpdateTaskCommand(updateTaskCommand: UpdateTaskCommand) {
    this.updateTaskCommand = updateTaskCommand;
  }

  @Mutation
  public setTaskLoaded(taskLoaded: boolean) {
    this.taskLoaded = taskLoaded;
  }

  @Mutation
  public setDocumentLoaded(documentLoaded: boolean) {
    this.documentLoaded = documentLoaded;
  }

  @Mutation
  public setTaskDeletingInProgress(taskDeletingInProgress: boolean) {
    this.taskDeletingInProgress = taskDeletingInProgress;
  }

  @Action
  public async getTaskAndDocument(taskId: string): Promise<void> {
    await this.getTask(taskId);
  }

  @Action
  public async getTask(taskId: string): Promise<void> {
    try {
      const task = await this.tasksClient.get(taskId);
      await this.setUpdateTaskCommand(
        new UpdateTaskCommand({
          id: task.id,
          name: task.name,
          dateTime:
            task.dateTime == undefined
              ? undefined
              : moment(task.dateTime).format("DD.MM.YYYY HH:mm"),
          description: task.description,
          documentId: task.documentId,
          userId: task.userId,
          viewed: task.viewed,
          completed: task.completed,
          type: task.type,
          meetingStatus: task.meetingStatus,
        })
      );
      this.setTaskLoaded(true);
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(
          e as ValidationProblemDetails,
          null,
          null,
          i18n.t("tasksEdit.errorGettingTask") as string,
          true
        )
      );
    }
  }

  @Action
  public async updateTask(): Promise<void> {
    try {
      this.setTaskUpdatingInProgress(true);
      await this.tasksClient.update(
        this.updateTaskCommand.id,
        new UpdateTaskCommand({
          id: this.updateTaskCommand.id,
          name: this.updateTaskCommand.name,
          dateTime:
            this.updateTaskCommand.dateTime == "" ||
            this.updateTaskCommand.dateTime == null ||
            !moment(
              this.updateTaskCommand.dateTime,
              "DD.MM.YYYY HH:mm"
            ).isValid()
              ? undefined
              : moment(
                  this.updateTaskCommand.dateTime,
                  "DD.MM.YYYY HH:mm"
                ).format("YYYY-MM-DDTHH:mm:ss"),
          description: this.updateTaskCommand.description,
          documentId: this.updateTaskCommand.documentId,
          userId: this.updateTaskCommand.userId,
          viewed: this.updateTaskCommand.viewed,
          completed: this.updateTaskCommand.completed,
          type: this.updateTaskCommand.type,
          meetingStatus: this.updateTaskCommand.meetingStatus,
        })
      );
      await router.push({
        name: "tasks-view",
        params: { taskId: this.updateTaskCommand.id },
      });
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(
          e as ValidationProblemDetails,
          ["name, dateTime", "description"],
          null,
          i18n.t("tasksView.errorUpdatingTask") as string,
          true
        )
      );
    } finally {
      this.setTaskUpdatingInProgress(false);
      DocumentStore.resetTasks();
    }
  }

  @Action
  public async reset() {
    this.setUpdateTaskCommand(new UpdateTaskCommand());
    this.setTaskLoaded(false);
    this.setDocumentLoaded(false);
    this.setTaskDeletingInProgress(false);
  }
}
