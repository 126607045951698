import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store, { ErrorsStore } from "@/store";
import {
  CreateUserCommand,
  UsersClient,
  ValidationProblemDetails,
} from "@/api-client";
import router from "@/router";
import ErrorsFromException from "@/types/errorsFromException";

@Module({
  name: "registrationPhone",
  namespaced: true,
  store,
})
export default class RegistrationPhoneModule extends VuexModule {
  private usersClient = new UsersClient() as UsersClient;
  public registrationInProgress = false;

  @Mutation
  public setRegistrationInProcess(registrationInProcess: boolean): void {
    this.registrationInProgress = registrationInProcess;
  }

  @Action
  public async create(phoneNumber: string): Promise<void> {
    this.setRegistrationInProcess(true);
    try {
      await this.usersClient.create(
        new CreateUserCommand({ phoneNumber: phoneNumber })
      );
      this.setRegistrationInProcess(false);
      localStorage.setItem("phoneNumber", phoneNumber);
      await router.push({ name: "registration-confirmation" });
      return;
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(
          e as ValidationProblemDetails,
          ["phoneNumber"],
          "phoneNumber"
        )
      );
      this.setRegistrationInProcess(false);
      return;
    }
  }
}
