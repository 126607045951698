import {
  CreateDocumentCommand,
  DocumentContentsClient,
  DocumentsClient,
  FileParameter,
  ValidationProblemDetails,
} from "@/api-client";
import i18n from "@/i18n";
import store, { AuthStore, ErrorsStore } from "@/store";
import ErrorsFromException from "@/types/errorsFromException";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import moment from "moment";

@Module({
  name: "documentCreate",
  namespaced: true,
  store,
})
export default class DocumentCreateModule extends VuexModule {
  private documentsClient = new DocumentsClient();
  private contentsClient = new DocumentContentsClient();

  public date = moment().format("YYYY-MM-DD");
  public medicalOrganizationName = null as string | null;
  public tags = new Array<string>();
  public documentCreatingInProgress = false;

  public contentsAddPending = new Array<FileParameter>();
  public contentsProcessPendingInProgress = false;
  public fileSizeDialog = false;

  // Document

  @Mutation
  public setDocumentDate(date: string) {
    this.date = date;
  }

  @Mutation
  public setMedicalOrganizationName(name: string | null) {
    this.medicalOrganizationName = name;
  }

  @Mutation
  public addDocumentTag(tag: string) {
    this.tags.push(tag);
  }

  @Mutation
  public removeDocumentTag(index: number) {
    this.tags.splice(index, 1);
  }

  @Mutation
  public setDocumentCreatingInProgress(documentCreatingInProgress: boolean) {
    this.documentCreatingInProgress = documentCreatingInProgress;
  }

  @Mutation
  public resetDocument() {
    this.date = moment().format("YYYY-MM-DD");
    this.medicalOrganizationName = null;
    this.tags = new Array<string>();
    this.documentCreatingInProgress = false;
  }

  // Contents

  @Mutation
  public addContentsAddPending(file: File) {
    const contents = {
      data: file,
      fileName: file.name,
    } as FileParameter;

    this.contentsAddPending.unshift(contents);
  }

  @Mutation
  public deleteContentsAddPending(index: number) {
    this.contentsAddPending.splice(index, 1);
  }

  @Mutation
  public setContentsProcessPendingInProgress(
    contentsProcessPendingInProgress: boolean
  ) {
    this.contentsProcessPendingInProgress = contentsProcessPendingInProgress;
  }

  @Mutation
  public resetContents() {
    this.contentsAddPending = new Array<FileParameter>();
    this.contentsProcessPendingInProgress = false;
  }

  @Mutation
  public setFileSizeDialog(fileSizeDialog: boolean) {
    this.fileSizeDialog = fileSizeDialog;
  }

  // Actions

  @Action
  public async createDocument(): Promise<string | undefined> {
    try {
      this.setDocumentCreatingInProgress(true);

      const createdDocument = await this.documentsClient.create(
        new CreateDocumentCommand({
          userId: AuthStore.userId ?? "",
          date: this.date,
          medicalOrganizationName:
            this.medicalOrganizationName == "" ||
            this.medicalOrganizationName == null
              ? undefined
              : this.medicalOrganizationName,
          tags: this.tags,
        })
      );

      return createdDocument.id;
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(
          e as ValidationProblemDetails,
          null,
          null,
          i18n.tc("documentCreateModule.errorCreatingDocument"),
          true
        )
      );
    } finally {
      this.setDocumentCreatingInProgress(false);
    }
  }

  @Action
  public async createContents(documentId: string) {
    try {
      this.setContentsProcessPendingInProgress(true);

      for (const content of this.contentsAddPending)
        await this.contentsClient.create(content, documentId);
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(
          e as ValidationProblemDetails,
          null,
          null,
          i18n.tc("documentCreateModule.errorCreatingContents"),
          true
        )
      );
    } finally {
      this.setContentsProcessPendingInProgress(false);
    }
  }

  @Action
  public reset() {
    this.resetDocument();
    this.resetContents();
    this.setFileSizeDialog(false);
  }

  @Action
  public async create() {
    const createdDocumentId = await this.createDocument();
    if (createdDocumentId != null) {
      await this.createContents(createdDocumentId);
      this.reset();
    }
    return createdDocumentId;
  }
}
