import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store, { ErrorsStore } from "@/store";
import {
  UpdateUserCommand,
  UsersClient,
  ValidationProblemDetails,
} from "@/api-client";
import router from "@/router";
import ErrorsFromException from "@/types/errorsFromException";

@Module({
  name: "registrationProfile",
  namespaced: true,
  store,
})
export default class RegistrationProfileModule extends VuexModule {
  private usersClient = new UsersClient() as UsersClient;
  public updateUserInProgress = false;

  @Mutation
  public setUpdateUserInProgress(createPasswordInProgress: boolean): void {
    this.updateUserInProgress = createPasswordInProgress;
  }

  @Action
  public async update(updateUserCommand: UpdateUserCommand): Promise<void> {
    this.setUpdateUserInProgress(true);
    try {
      await this.usersClient.update(updateUserCommand.id, updateUserCommand);
      this.setUpdateUserInProgress(false);
      await router.push({ name: "documents" });
      return;
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(e as ValidationProblemDetails, [
          "surname",
          "name",
          "patronymic",
          "dateOfBirth",
          "email",
        ])
      );
      this.setUpdateUserInProgress(false);
      return;
    }
  }
}
