import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import "./vee-validate";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import moment from "moment";
import VueCookies from "vue-cookies";
import VueYandexMetrika from "vue-yandex-metrika";
import OneSignalVue from "onesignal-vue";
import { VueMaskDirective } from "v-mask";
import { config } from "vuex-module-decorators";
config.rawError = true;

Vue.use(VueYandexMetrika, {
  id: 70449142,
  router: router,
  env: process.env.NODE_ENV,
  scriptSrc: "/tag.js",
});
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.use(VueCookies);
Vue.config.productionTip = false;
Vue.use(OneSignalVue);
Vue.directive("mask", VueMaskDirective);
moment.locale("ru");

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
