import ErrorCollection from "@/types/errorCollection";
import i18n from "@/i18n";

export default class ErrorCollectionResolver {
  public static resolve(
    errorsFromApi: { [key: string]: string[] } | undefined,
    knownFields: string[] | null = null,
    putUnknownErrorsToField: string | null,
    unknownError: string = i18n.t("error.unknown") as string
  ): ErrorCollection {
    const observerErrors = {} as { [key: string]: string[] };
    let errors = [] as string[];
    let anyError = false;

    for (const key in errorsFromApi) {
      anyError = true;
      if (knownFields != null && knownFields.includes(key)) {
        observerErrors[key] = errorsFromApi[key];
      } else {
        if (putUnknownErrorsToField == null) {
          errors = errors.concat(errorsFromApi[key]);
        } else {
          if (putUnknownErrorsToField in observerErrors) {
            observerErrors[putUnknownErrorsToField] = observerErrors[
              putUnknownErrorsToField
            ].concat(errorsFromApi[key]);
          } else {
            observerErrors[putUnknownErrorsToField] = errorsFromApi[key];
          }
        }
      }
    }

    if (!anyError) {
      if (putUnknownErrorsToField == null) {
        errors.push(unknownError);
      } else {
        if (putUnknownErrorsToField in observerErrors) {
          observerErrors[putUnknownErrorsToField].push(unknownError);
        } else {
          observerErrors[putUnknownErrorsToField] = [unknownError];
        }
      }
    }

    return {
      observerErrors: observerErrors,
      errors: errors,
    } as ErrorCollection;
  }
}
