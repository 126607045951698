import { Action, Module, VuexModule } from "vuex-module-decorators";
import store, { AuthStore } from "@/store";
import {
  DocumentContentsClient,
  FileParameter,
  FileResponse,
} from "@/api-client";

@Module({
  name: "documentContent",
  namespaced: true,
  store,
})
export default class DocumentContentModule extends VuexModule {
  private documentContentsClient = new DocumentContentsClient();

  @Action
  public async getContent(documentId: string): Promise<FileResponse | null> {
    try {
      const documentContents =
        await this.documentContentsClient.getWithPagination(
          documentId,
          AuthStore.userId,
          null,
          null,
          null,
          1,
          1
        );
      if (
        documentContents.items == undefined ||
        documentContents.items.length == 0 ||
        documentContents.items[0].id == undefined
      ) {
        return null;
      }

      return await this.documentContentsClient.get(
        documentContents.items[0].id
      );
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  @Action
  public async create({
    documentId,
    content,
  }: {
    documentId: string;
    content: FileParameter;
  }): Promise<FileResponse | null> {
    try {
      await this.documentContentsClient.create(content, documentId);
      return {} as FileResponse;
    } catch (e) {
      console.log(e);
      return null;
    }
  }
}
