import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import store from "@/store";
import ErrorCollectionResolver from "@/types/errorCollectionResolver";
import ErrorsFromException from "@/types/errorsFromException";

@Module({
  name: "errors",
  namespaced: true,
  store,
})
export default class ErrorsModule extends VuexModule {
  public observerErrors = null as null | Record<string, string[] | string>;
  public errors = null as null | string[];
  public snackbar = false;

  @Mutation
  public setObserverErrors(
    observerErrors: null | Record<string, string[] | string>
  ): void {
    this.observerErrors = observerErrors;
  }

  @Mutation
  public setErrors(errors: null | string[]): void {
    this.errors = errors;
  }

  @Mutation
  public setSnackbar(snackbar: boolean) {
    this.snackbar = snackbar;
  }

  @Action
  public setErrorsFromException(
    errorsFromException: ErrorsFromException
  ): void {
    const errorCollection = ErrorCollectionResolver.resolve(
      errorsFromException.error?.errors,
      errorsFromException.knownFields,
      errorsFromException.putUnknownErrorsToField,
      errorsFromException.unknownError
    );
    this.setObserverErrors(errorCollection.observerErrors);
    this.setErrors(errorCollection.errors);
    if (errorsFromException.putUnknownErrorsToSnackBar) {
      this.setSnackbar(true);
    }
  }
}
