import { Action, Module, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import {
  CreateNotificationTokenCommand,
  NotificationTokenClient,
} from "@/api-client";

@Module({
  name: "notificationTokens",
  namespaced: true,
  store,
})
export default class NotificationTokensModule extends VuexModule {
  private notificationTokenClient =
    new NotificationTokenClient() as NotificationTokenClient;

  @Action
  public async create(token: string): Promise<void> {
    try {
      await this.notificationTokenClient.create(
        new CreateNotificationTokenCommand({ token: token })
      );
    } catch (e) {
      console.log(e);
    }
  }

  @Action
  public async delete(token: string): Promise<string | null> {
    try {
      return await this.notificationTokenClient.delete(token);
    } catch (e) {
      console.log(e);
      return null;
    }
  }
}
