import { CertificateStatus } from "@/api-client";
import i18n from "@/i18n";

export default class CertificateStatusHelper {
  public static ConvertStatusToString(
    certificateStatus: CertificateStatus
  ): string {
    switch (certificateStatus) {
      case CertificateStatus.Sent:
        return i18n.tc("certificateStatus.sent");
      case CertificateStatus.Received:
        return i18n.tc("certificateStatus.received");
      case CertificateStatus.Rejected:
        return i18n.tc("certificateStatus.rejected");
      case CertificateStatus.Generated:
        return i18n.tc("certificateStatus.generated");
      case CertificateStatus.Error:
        return i18n.tc("certificateStatus.error");
    }
    return i18n.tc("certificateStatus.unknown");
  }
}
