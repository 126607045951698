export default class OneSignalPromptOptions {
  constructor(data?: OneSignalPromptOptions) {
    if (data) {
      for (const property in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty(property))
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  // Text that says 'wants to show notifications' by default. Limited to 75 characters.
  public actionMessage!: string;

  // Text that says 'This is an example notification'.
  // Displays on non-mobile devices. Only one line is allowed.
  public exampleNotificationTitleDesktop!: string;

  // Text that says 'Notifications will appear on your desktop'.
  // Displays on non-mobile devices. Only one line is allowed.
  public exampleNotificationMessageDesktop!: string;

  // Text that says 'This is an example notification'.
  // Displays on mobile devices with limited screen width. Only one line is allowed.
  public exampleNotificationTitleMobile!: string;

  // Text that says 'Notifications will appear on your device'.
  // Displays on mobile devices with limited screen width. Only one line is allowed.
  public exampleNotificationMessageMobile!: string;

  // Text that says '(you can unsubscribe anytime)'.
  public exampleNotificationCaption!: string;

  // Text that says 'CONTINUE'.
  public acceptButtonText!: string;

  // Text that says 'NO THANKS'.
  public cancelButtonText!: string;

  // Set to false to hide the OneSignal logo.
  public showCredit!: boolean;
}
