import OneSignalOptions from "@/types/oneSignal/oneSignalOptions";
import OneSignalPromptOptions from "@/types/oneSignal/oneSignalPromptOptions";
import OneSignalWelcomeNotificationOptions from "@/types/oneSignal/oneSignalWelcomeNotificationOptions";
import OneSignalNotifyButtonOptions from "@/types/oneSignal/oneSignalNotifyButtonOptions";

const value = new OneSignalOptions({
  appId: process.env.VUE_APP_ONE_SIGNAL_APP_ID ?? "",
  safari_web_id: process.env.VUE_APP_SAFARI_WEB_ID ?? "",
  subdomainName: "",
  requiresUserPrivacyConsent: true,
  promptOptions: new OneSignalPromptOptions({
    actionMessage: "Вы хотите подписаться на уведомления?",
    exampleNotificationTitleDesktop: "Befordoc",
    exampleNotificationMessageDesktop: "Пример уведомления о новом документе",
    exampleNotificationTitleMobile: "Befordoc",
    exampleNotificationMessageMobile: "Пример уведомления о новом документе",
    exampleNotificationCaption: "Дополнительная информация",
    acceptButtonText: "Да",
    cancelButtonText: "Нет",
    showCredit: false,
  }),
  welcomeNotification: new OneSignalWelcomeNotificationOptions({
    disable: false,
    title: "Befordoc",
    message: "Теперь вы будете получать такие уведомления о новых документах",
    url: "",
  }),
  notifyButton: new OneSignalNotifyButtonOptions({
    enable: true,
    displayPredicate: () => true,
    size: "small",
    position: "bottom-right",
    offset: null,
    prenotify: false,
    showCredit: false,
    text: {
      "tip.state.unsubscribed": "Подписаться на уведомления",
      "tip.state.subscribed": "Вы подписаны на уведомления",
      "tip.state.blocked": "Вы заблокировали уведомления",
      "message.prenotify": "Нажмите, чтобы подписаться на уведомления",
      "message.action.subscribed": "Спасибо за подписку!",
      "message.action.resubscribed": "Вы подписаны на уведомления",
      "message.action.unsubscribed": "Вы больше не будете получать уведомления",
      "dialog.main.title": "Уведомления",
      "dialog.main.button.subscribe": "Подписаться",
      "dialog.main.button.unsubscribe": "Отписаться",
      "dialog.blocked.title": "Разблокировать уведомления",
      "dialog.blocked.message":
        "Следуйте этим инструкциям, чтобы разрешить уведомления:",
    },
  }),
  persistNotification: true,
  webhooks: null,
  autoResubscribe: true,
  autoRegister: false,
  notificationClickHandlerMatch: "",
  notificationClickHandlerAction: "",
  path: "",
  allowLocalhostAsSecureOrigin: true,
});

export default value;
