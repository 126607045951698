import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store, { DocumentStore, ErrorsStore } from "@/store";
import {
  CreateTaskCommand,
  TasksClient,
  ValidationProblemDetails,
} from "@/api-client";
import router from "@/router";
import ErrorsFromException from "@/types/errorsFromException";
import i18n from "@/i18n";

@Module({
  name: "taskCreate",
  namespaced: true,
  store,
})
export default class TaskCreateModule extends VuexModule {
  private tasksClient = new TasksClient();
  public createTaskInProgress = false;

  @Mutation
  public setCreateTaskInProgress(createTaskInProgress: boolean): void {
    this.createTaskInProgress = createTaskInProgress;
  }

  @Action
  public async create(command: CreateTaskCommand) {
    try {
      this.setCreateTaskInProgress(true);
      await this.tasksClient.create(command);
      await router.push({ name: "tasks" });
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(
          e as ValidationProblemDetails,
          ["name", "dateTime", "description"],
          null,
          i18n.t("tasksCreate.errorCreatingTask") as string,
          true
        )
      );
    } finally {
      this.setCreateTaskInProgress(false);
      DocumentStore.resetTasks();
    }
  }
}
