import { Module, VuexModule, Action } from "vuex-module-decorators";
import store from "@/store";
import { UpdateUserCommand, UserDto, UsersClient } from "@/api-client";

@Module({
  name: "profiles",
  namespaced: true,
  store,
})
export default class UsersModule extends VuexModule {
  private usersClient = new UsersClient() as UsersClient;

  @Action
  public async get(id: string): Promise<UserDto | null> {
    try {
      return await this.usersClient.get(id);
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  @Action
  public async update(command: UpdateUserCommand): Promise<void> {
    try {
      return await this.usersClient.update(command.id, command);
    } catch (e) {
      console.log(e);
    }
  }
}
