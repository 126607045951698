export default class Pages {
  public static NeedAuthorize = [
    "profile",
    "documents-create",
    "documents",
    "documents-view",
    "feedback",
    "registration-password",
    "registration-profile",
    "tasks-create",
    "tasks",
    "tasks-view",
    "tasks-edit",
    "certificates",
    "certificates-create",
  ];

  public static NeedRedirectIfAuthorize = [
    "home",
    "login",
    "password-reset",
    "registration",
    "phone-number-confirmation",
    "registration-phone",
    "registration-confirmation",
  ];

  public static NeedBack = [
    "profile",
    "documents-create",
    "documents",
    "documents-view",
    "phone-number-confirmation",
    "registration",
    "password-reset",
    "feedback",
    "privacy",
  ];
}
