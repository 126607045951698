import { Module, VuexModule, Action } from "vuex-module-decorators";
import store from "@/store";
import { SloganDto, SlogansClient } from "@/api-client";
import PageCollector from "@/types/pageCollector";

@Module({
  name: "slogans",
  namespaced: true,
  store,
})
export default class SlogansModule extends VuexModule {
  private sloganClient = new SlogansClient() as SlogansClient;

  @Action
  public async getAllPages(): Promise<SloganDto[] | null> {
    try {
      const pageCollector = new PageCollector();
      return await pageCollector.getAllPagesContent<SloganDto>(
        async ({ pageNumber, pageSize }) => {
          const result = await this.sloganClient.getWithPagination(
            null,
            pageNumber,
            pageSize
          );
          return { items: result?.items, totalPages: result?.totalPages };
        }
      );
    } catch (e) {
      console.log(e);
      return null;
    }
  }
}
