import Vue from "vue";
import Vuetify from "vuetify/lib";
import i18n from "@/i18n";
import BottomDocumentsIcon from "@/components/icons/BottomDocumentsIcon.vue";
import BottomTasksIcon from "@/components/icons/BottomTasksIcon.vue";
import BottomProfileIcon from "@/components/icons/BottomProfileIcon.vue";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import CircleCheckIcon from "@/components/icons/CircleCheckIcon.vue";
import PlusIcon from "@/components/icons/PlusIcon.vue";
import TrashIcon from "@/components/icons/TrashIcon.vue";
import PencilIcon from "@/components/icons/PencilIcon.vue";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdiSvg",
    values: {
      bottomDocumentsIcon: {
        component: BottomDocumentsIcon,
      },
      bottomTasksIcon: {
        component: BottomTasksIcon,
      },
      bottomProfileIcon: {
        component: BottomProfileIcon,
      },
      circleIcon: {
        component: CircleIcon,
      },
      circleCheckIcon: {
        component: CircleCheckIcon,
      },
      plusIcon: {
        component: PlusIcon,
      },
      trashIcon: {
        component: TrashIcon,
      },
      pencilIcon: {
        component: PencilIcon,
      },
      iosArrowLeft:
        "m4.296 12 8.492-8.727a.75.75 0 1 0-1.075-1.046l-9 9.25a.75.75 0 0 0 0 1.046l9 9.25a.75.75 0 1 0 1.075-1.046L4.295 12Z",
      iosArrowRight:
        "m19.704 12-8.492-8.727a.75.75 0 1 1 1.075-1.046l9 9.25a.75.75 0 0 1 0 1.046l-9 9.25a.75.75 0 1 1-1.075-1.046L19.705 12Z",
      bottomDocuments: "",
      bottomProfile: "",
      bottomTasks: "",
    },
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#1498f9",
        primary2: "#18a0fb",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        darkBlue: "#172B4D",
        lightBlue: "#1963d1",
        gray2: "#f5f7fa",
        darkGray2: "#6b778c",
        white: "#ffffff",
      },
    },
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params) as string,
  },
});
