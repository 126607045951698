import { extend, configure } from "vee-validate";
import { required, email, max, min, min_value } from "vee-validate/dist/rules";
import i18n from "./i18n";
import PhoneNumber from "awesome-phonenumber";
import { setInteractionMode } from "vee-validate";
import moment from "moment";

setInteractionMode("eager");

configure({
  defaultMessage: (field, values) => {
    values._field_ = i18n.t(`${field}`);
    return i18n.t(`validation.${values._rule_}`, values) as string;
  },
});

extend("required", required);
extend("email", email);
extend("max", max);
extend("min", min);
extend("min-files-count", min_value);
extend("phone", {
  validate(value) {
    return new Promise((resolve) => {
      const phone = new PhoneNumber(value);
      resolve({ valid: phone.isValid() });
    });
  },
});
extend("phone2", {
  validate(value) {
    return new Promise((resolve) => {
      const phone = new PhoneNumber("+7" + value.replace(/[^+\d]/g, ""));
      resolve({ valid: phone.isValid() });
    });
  },
});
extend("phone3", {
  validate(value) {
    return new Promise((resolve) => {
      const phone = new PhoneNumber(value.replace(/[^+\d]/g, ""));
      resolve({ valid: phone.isValid() });
    });
  },
});
extend("password", {
  params: ["target"],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validate(value, params: any) {
    return value === params["target"];
  },
});
extend("date", {
  validate(value) {
    return new Promise((resolve) => {
      if (value == null || value == undefined || value == "") {
        resolve({ valid: true });
        return;
      }

      if (value.length != 10) {
        resolve({ valid: false });
        return;
      }

      const date = moment(value, "DD.MM.YYYY");
      resolve({ valid: date.isValid() });
    });
  },
});
extend("date_time", {
  validate(value) {
    return new Promise((resolve) => {
      if (value == null || value == undefined || value == "") {
        resolve({ valid: true });
        return;
      }

      if (value.length != 16) {
        resolve({ valid: false });
        return;
      }

      const date = moment(value, "DD.MM.YYYY HH:mm");
      resolve({ valid: date.isValid() });
    });
  },
});
