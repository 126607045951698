import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store, { ErrorsStore } from "@/store";
import {
  CreatePasswordResetTokenCommand,
  TokensClient,
  ValidationProblemDetails,
} from "@/api-client";
import router from "@/router";
import ErrorsFromException from "@/types/errorsFromException";

@Module({
  name: "passwordRecoveryPhone",
  namespaced: true,
  store,
})
export default class PasswordRecoveryPhoneModule extends VuexModule {
  private tokensClient = new TokensClient() as TokensClient;
  public createPasswordResetInProgress = false;

  @Mutation
  public setCreatePasswordResetInProgress(
    createPasswordResetInProgress: boolean
  ): void {
    this.createPasswordResetInProgress = createPasswordResetInProgress;
  }

  @Action
  public async create(phoneNumber: string): Promise<void> {
    this.setCreatePasswordResetInProgress(true);
    try {
      const result = await this.tokensClient.createPasswordReset(
        new CreatePasswordResetTokenCommand({ phoneNumber: phoneNumber })
      );
      if (result.value != undefined) {
        alert(result.value);
      }
      this.setCreatePasswordResetInProgress(false);
      localStorage.setItem("phoneNumber", phoneNumber);
      await router.push({ name: "password-recovery-confirmation" });
      return;
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(
          e as ValidationProblemDetails,
          ["phoneNumber"],
          "phoneNumber"
        )
      );
      this.setCreatePasswordResetInProgress(false);
      return;
    }
  }
}
