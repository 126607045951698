import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store, { ErrorsStore } from "@/store";
import {
  CertificateContentDto,
  CertificateContentsClient,
  CertificateDto,
  CertificatesClient,
  CertificateStatus,
  CertificateType,
  OrganizationDto,
  OrganizationsClient,
  ValidationProblemDetails,
} from "@/api-client";
import ErrorsFromException from "@/types/errorsFromException";
import i18n from "@/i18n";
import router from "@/router";
import CertificateStatusHelper from "@/types/certificateStatusHelper";
import moment from "moment";

@Module({
  name: "certificateView",
  namespaced: true,
  store,
})
export default class CertificateViewModule extends VuexModule {
  private certificatesClient = new CertificatesClient();
  private organizationsClient = new OrganizationsClient();
  private certificateContentsClient = new CertificateContentsClient();

  // certificate id

  public certificateId = null as string | null;

  @Mutation
  public setCertificateId(certificateId: string | null): void {
    this.certificateId = certificateId;
  }

  // certificate

  public certificate = null as CertificateDto | null;

  @Mutation
  public setCertificate(certificate: CertificateDto | null): void {
    this.certificate = certificate;
  }

  // medical organization

  public medicalOrganization = null as OrganizationDto | null;

  @Mutation
  public setMedicalOrganization(
    medicalOrganization: OrganizationDto | null
  ): void {
    this.medicalOrganization = medicalOrganization;
  }

  // institution

  public institution = null as OrganizationDto | null;

  @Mutation
  public setInstitution(institution: OrganizationDto | null): void {
    this.institution = institution;
  }

  // delete

  public deleteInProgress = false;

  @Mutation
  public setDeleteInProgress(deleteInProgress: boolean): void {
    this.deleteInProgress = deleteInProgress;
  }

  public deleteDialog = false;

  @Mutation
  public setDeleteDialog(deleteDialog: boolean): void {
    this.deleteDialog = deleteDialog;
  }

  // content

  public contents = null as CertificateContentDto[] | null;

  @Mutation
  public setContents(contents: CertificateContentDto[] | null) {
    this.contents = contents;
  }

  @Action
  public async getCertificate() {
    if (this.certificateId == null) {
      console.log("CertificateId is null");
      return;
    }

    try {
      const certificate = await this.certificatesClient.get(this.certificateId);
      this.setCertificate(certificate);
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(
          e as ValidationProblemDetails,
          null,
          null,
          i18n.tc("certificateView.errorGettingCertificate"),
          true
        )
      );
    }
  }

  @Action
  public async getMedicalOrganization() {
    if (this.certificate == null) {
      console.log("Certificate is null");
      return;
    }

    try {
      const medicalOrganization = await this.organizationsClient.get(
        this.certificate.medicalOrganizationId
      );
      this.setMedicalOrganization(medicalOrganization);
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(
          e as ValidationProblemDetails,
          null,
          null,
          i18n.tc("certificateView.errorGettingMedicalOrganization"),
          true
        )
      );
    }
  }

  @Action
  public async getInstitution() {
    if (this.certificate == null) {
      console.log("Certificate is null");
      return;
    }

    try {
      const institution = await this.organizationsClient.get(
        this.certificate.institutionId
      );
      this.setInstitution(institution);
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(
          e as ValidationProblemDetails,
          null,
          null,
          i18n.tc("certificateView.errorGettingInstitution"),
          true
        )
      );
    }
  }

  @Action
  public async getContents() {
    if (this.certificate == null) {
      console.log("Certificate is null");
      return;
    }

    if (this.certificate.status != CertificateStatus.Generated) {
      this.setContents([]);
      return;
    }

    try {
      const result = await this.certificateContentsClient.getWithPagination(
        this.certificateId,
        null,
        null,
        null,
        null,
        1,
        1000
      );
      this.setContents(result.items);
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(
          e as ValidationProblemDetails,
          null,
          null,
          i18n.tc("certificateView.errorGettingContents"),
          true
        )
      );
    }
  }

  @Action
  public async getAll() {
    await this.getCertificate();
    await this.getMedicalOrganization();
    await this.getInstitution();
    await this.getContents();
  }

  @Action
  public async delete() {
    if (this.certificateId == null) {
      console.log("CertificateId is null");
      return;
    }

    try {
      this.setDeleteInProgress(true);
      await this.certificatesClient.delete(this.certificateId, true);
      await router.push({ name: "certificates" });
      await this.setDeleteDialog(false);
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(
          e as ValidationProblemDetails,
          null,
          null,
          i18n.tc("certificateView.errorDeletingCertificate"),
          true
        )
      );
    } finally {
      this.setDeleteInProgress(false);
    }
  }

  public get typeString() {
    if (this.certificate == null) {
      return "";
    }

    if (this.certificate.type == CertificateType.EpidemiologicalWellBeing) {
      return i18n.tc("certificateView.epidemiologicalWellBeing");
    }
  }

  public get statusString() {
    if (this.certificate == null) {
      return "";
    }

    return CertificateStatusHelper.ConvertStatusToString(
      this.certificate.status
    );
  }

  public get possibleToDelete() {
    if (this.certificate == null) {
      return false;
    }

    return (
      this.certificate.status == CertificateStatus.Sent ||
      this.certificate.status == CertificateStatus.Error
    );
  }

  public get deleteString() {
    if (this.certificate == null) {
      return "";
    }

    if (this.certificate.status == CertificateStatus.Sent) {
      return i18n.tc("certificateView.cancel");
    } else if (this.certificate.status == CertificateStatus.Error) {
      return i18n.tc("certificateView.delete");
    }

    return "";
  }

  public get deleteDialogString() {
    if (this.certificate == null) {
      return "";
    }

    if (this.certificate.status == CertificateStatus.Sent) {
      return i18n.tc("certificateView.dialogCancel");
    } else if (this.certificate.status == CertificateStatus.Error) {
      return i18n.tc("certificateView.dialogDelete");
    }

    return "";
  }

  public get issueDateString() {
    if (this.certificate == null) {
      return "";
    }

    if (this.certificate.issueDate == null) {
      return i18n.tc("certificateView.issueDateUnknown");
    }

    return moment(this.certificate.issueDate).format("DD.MM.YYYY");
  }

  @Action
  public reset() {
    this.setCertificateId(null);
    this.setCertificate(null);
    this.setMedicalOrganization(null);
    this.setInstitution(null);
    this.setDeleteInProgress(false);
    this.setContents(null);
    this.setDeleteDialog(false);
  }
}
