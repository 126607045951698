import Vue from "vue";
import Vuex from "vuex";
import { getModule } from "vuex-module-decorators";
import AuthModule from "@/store/modules/authModule";
import UsersModule from "@/store/modules/usersModule";
import DocumentModule from "./modules/documentModule";
import DocumentCreateModule from "./modules/documentCreateModule";
import DocumentsModule from "./modules/documentsModule";
import NotificationTokensModule from "@/store/modules/notificationTokensModule";
import DocumentContentModule from "@/store/modules/documentContentModule";
import AnonymousDocumentsModule from "@/store/modules/anonymousDocumentsModule";
import SlogansModule from "@/store/modules/slogansModule";
import RegistrationPhoneModule from "@/store/modules/registrationPhoneModule";
import RegistrationConfirmationModule from "@/store/modules/registrationConfirmationModule";
import ErrorsModule from "@/store/modules/errorsModule";
import RegistrationPasswordModule from "@/store/modules/registrationPasswordModule";
import RegistrationProfileModule from "@/store/modules/registrationProfileModule";
import LoginModule from "@/store/modules/loginModule";
import PasswordRecoveryPhoneModule from "@/store/modules/passwordRecoveryPhoneModule";
import PasswordRecoveryConfirmationModule from "@/store/modules/passwordRecoveryConfirmationModule";
import PasswordRecoveryPasswordModule from "@/store/modules/passwordRecoveryPasswordModule";
import TaskCreateModule from "@/store/modules/taskCreateModule";
import TasksModule from "@/store/modules/tasksModule";
import TaskViewModule from "@/store/modules/taskViewModule";
import TaskEditModule from "@/store/modules/taskEditModule";
import PartnersModule from "@/store/modules/partnersModule";
import CertificateModule from "@/store/modules/certificates/certificateModule";
import CertificateCreateModule from "@/store/modules/certificates/certificateCreateModule";
import CertificateViewModule from "@/store/modules/certificates/certificateViewModule";
import CertificateContentViewModule from "@/store/modules/certificates/certificateContentViewModule";

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  modules: {
    auth: AuthModule,
    profiles: UsersModule,
    document: DocumentModule,
    documentCreate: DocumentCreateModule,
    documents: DocumentsModule,
    anonymousDocuments: AnonymousDocumentsModule,
    notificationTokens: NotificationTokensModule,
    documentContent: DocumentContentModule,
    slogans: SlogansModule,
    registrationPhone: RegistrationPhoneModule,
    registrationConfirmation: RegistrationConfirmationModule,
    errors: ErrorsModule,
    registrationPassword: RegistrationPasswordModule,
    registrationProfile: RegistrationProfileModule,
    login: LoginModule,
    passwordRecoveryPhone: PasswordRecoveryPhoneModule,
    passwordRecoveryConfirmation: PasswordRecoveryConfirmationModule,
    passwordRecoveryPassword: PasswordRecoveryPasswordModule,
    taskCreate: TaskCreateModule,
    tasks: TasksModule,
    taskView: TaskViewModule,
    taskEdit: TaskEditModule,
    partners: PartnersModule,
    certificate: CertificateModule,
    certificateCreate: CertificateCreateModule,
    certificateView: CertificateViewModule,
    certificateContentView: CertificateContentViewModule,
  },
});

export default store;
export const AuthStore = getModule(AuthModule, store);
export const UsersStore = getModule(UsersModule, store);
export const DocumentStore = getModule(DocumentModule, store);
export const DocumentCreateStore = getModule(DocumentCreateModule, store);
export const DocumentsStore = getModule(DocumentsModule, store);
export const AnonymousDocumentsStore = getModule(
  AnonymousDocumentsModule,
  store
);
export const NotificationTokensStore = getModule(
  NotificationTokensModule,
  store
);
export const DocumentContentStore = getModule(DocumentContentModule, store);
export const SlogansStore = getModule(SlogansModule, store);
export const RegistrationPhoneStore = getModule(RegistrationPhoneModule, store);
export const RegistrationConfirmationStore = getModule(
  RegistrationConfirmationModule,
  store
);
export const ErrorsStore = getModule(ErrorsModule, store);
export const RegistrationPasswordStore = getModule(
  RegistrationPasswordModule,
  store
);
export const RegistrationProfileStore = getModule(
  RegistrationProfileModule,
  store
);
export const LoginStore = getModule(LoginModule, store);
export const PasswordRecoveryPhoneStore = getModule(
  PasswordRecoveryPhoneModule,
  store
);
export const PasswordRecoveryConfirmationStore = getModule(
  PasswordRecoveryConfirmationModule,
  store
);
export const PasswordRecoveryPasswordStore = getModule(
  PasswordRecoveryPasswordModule,
  store
);
export const TaskCreateStore = getModule(TaskCreateModule, store);
export const TasksStore = getModule(TasksModule, store);
export const TaskViewStore = getModule(TaskViewModule, store);
export const TaskEditStore = getModule(TaskEditModule, store);
export const PartnersStore = getModule(PartnersModule, store);
export const CertificateStore = getModule(CertificateModule, store);
export const CertificateCreateStore = getModule(CertificateCreateModule, store);
export const CertificateViewStore = getModule(CertificateViewModule, store);
export const CertificateContentViewStore = getModule(
  CertificateContentViewModule,
  store
);
