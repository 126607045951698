import {
  DocumentContentDto,
  DocumentContentsClient,
  DocumentDto,
  DocumentsClient,
  FileParameter,
  FileResponse,
  Operation,
  OperationType,
  TaskDto,
  TasksClient,
  UpdateDocumentCommand,
  ValidationProblemDetails,
} from "@/api-client";
import i18n from "@/i18n";
import store, { ErrorsStore } from "@/store";
import ErrorsFromException from "@/types/errorsFromException";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({
  name: "document",
  namespaced: true,
  store,
})
export default class DocumentModule extends VuexModule {
  private documentsClient = new DocumentsClient();
  private contentsClient = new DocumentContentsClient();
  private tasksClient = new TasksClient();

  public documentId = null as string | null;

  public document = null as DocumentDto | null;
  public documentLoaded = false;
  public documentUpdatingInProgress = false;
  public documentDeletingInProgress = false;

  public contents = null as DocumentContentDto[] | null;
  public contentsLoaded = false;
  public contentsAddPending = new Array<FileParameter>();
  public contentsDeletePending = new Array<DocumentContentDto>();
  public contentsProcessPendingInProgress = false;

  public contentId = null as string | null;

  public content = null as FileResponse | null;
  public contentLoaded = false;
  public contentLoadedId = null as string | null;

  public tasks = null as TaskDto[] | null;
  public tasksLoaded = false;
  public tasksDisconnectingPending = new Array<TaskDto>();
  public tasksDisconnectingPendingInProgress = false;
  public fileSizeDialog = false;

  // Document

  @Mutation
  public setDocumentId(documentId: string) {
    this.documentId = documentId;
  }

  @Mutation
  public setDocument(document: DocumentDto) {
    this.document = document;
  }

  @Mutation
  public setMedicalOrganizationName(name: string | null) {
    if (this.document != null)
      this.document.medicalOrganizationName = name ?? undefined;
  }

  @Mutation
  public addDocumentTag(tag: string) {
    this.document?.tags?.push(tag);
  }

  @Mutation
  public removeDocumentTag(index: number) {
    this.document?.tags?.splice(index, 1);
  }

  @Mutation
  public setDocumentLoaded(documentLoaded: boolean) {
    this.documentLoaded = documentLoaded;
  }

  @Mutation
  public setDocumentUpdatingInProgress(documentUpdatingInProgress: boolean) {
    this.documentUpdatingInProgress = documentUpdatingInProgress;
  }

  @Mutation
  public setDocumentDeletingInProgress(documentDeletingInProgress: boolean) {
    this.documentDeletingInProgress = documentDeletingInProgress;
  }

  @Mutation
  public resetDocument() {
    // documentId не сбрасываем
    this.document = null;
    this.documentLoaded = false;
    this.documentUpdatingInProgress = false;
    this.documentDeletingInProgress = false;
  }

  // Contents

  @Mutation
  public setContents(contents: DocumentContentDto[]) {
    this.contents = contents;
  }

  @Mutation
  public setContentsLoaded(contentsLoaded: boolean) {
    this.contentsLoaded = contentsLoaded;
  }

  @Mutation
  public addContentsAddPending(file: File) {
    const contents = {
      data: file,
      fileName: file.name,
    } as FileParameter;

    this.contentsAddPending.unshift(contents);
  }

  @Mutation
  public deleteContentsAddPending(index: number) {
    this.contentsAddPending.splice(index, 1);
  }

  @Mutation
  public addContentsDeletePending(index: number) {
    if (this.contents != null) {
      const content = this.contents.at(index);
      if (content != null) {
        this.contents.splice(index, 1);
        this.contentsDeletePending.push(content);
      }
    }
  }

  @Mutation
  public setContentsProcessPendingInProgress(
    contentsProcessPendingInProgress: boolean
  ) {
    this.contentsProcessPendingInProgress = contentsProcessPendingInProgress;
  }

  @Mutation
  public resetContents() {
    this.contents = null;
    this.contentsLoaded = false;
    this.contentsAddPending = new Array<FileParameter>();
    this.contentsDeletePending = new Array<DocumentContentDto>();
    this.contentsProcessPendingInProgress = false;
  }

  // Content

  @Mutation
  public setContentId(contentId: string) {
    this.contentId = contentId;
  }

  @Mutation
  public setContent(content: FileResponse) {
    this.content = content;
  }

  @Mutation
  public setContentLoaded(contentLoaded: boolean) {
    this.contentLoaded = contentLoaded;
  }

  @Mutation
  public setContentLoadedId(contentLoadedId: string) {
    this.contentLoadedId = contentLoadedId;
  }

  @Mutation
  public resetContent() {
    // contentId не сбрасываем
    this.content = null;
    this.contentLoaded = false;
    this.contentLoadedId = null;
  }

  @Mutation
  public setFileSizeDialog(fileSizeDialog: boolean) {
    this.fileSizeDialog = fileSizeDialog;
  }

  // Tasks

  @Mutation
  public setTasks(tasks: TaskDto[]) {
    this.tasks = tasks;
  }

  @Mutation
  public setTaskLoaded(tasksLoaded: boolean) {
    this.tasksLoaded = tasksLoaded;
  }

  @Mutation
  public addTasksDisconnectingPending(index: number) {
    if (this.tasks != null) {
      const task = this.tasks.at(index);
      if (task != null) {
        this.tasks.splice(index, 1);
        this.tasksDisconnectingPending.push(task);
      }
    }
  }

  @Mutation
  public setTasksDisconnectingPendingInProgress(
    tasksDisconnectingPendingInProgress: boolean
  ) {
    this.tasksDisconnectingPendingInProgress =
      tasksDisconnectingPendingInProgress;
  }

  @Mutation
  public resetTasks() {
    this.tasks = null;
    this.tasksLoaded = false;
    this.tasksDisconnectingPending = new Array<TaskDto>();
    this.tasksDisconnectingPendingInProgress = false;
  }

  // Actions

  @Action
  public reset(force = true) {
    if (
      force ||
      this.documentId == null ||
      this.document == null ||
      this.documentId != this.document.id
    ) {
      this.resetDocument();
      this.resetContents();
      this.resetContent();
      this.resetTasks();
      this.setFileSizeDialog(false);
    }
  }

  @Action
  public async loadDocument() {
    if (this.documentId == null) {
      this.reset();
      return;
    }

    try {
      this.setDocument(await this.documentsClient.get(this.documentId));

      this.setDocumentLoaded(true);
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(
          e as ValidationProblemDetails,
          null,
          null,
          i18n.tc("documentModule.errorGettingDocument"),
          true
        )
      );
    }
  }

  @Action
  public async loadContents() {
    if (this.documentId == null) {
      this.reset();
      return;
    }

    try {
      this.setContents(
        (
          await this.contentsClient.getWithPagination(
            this.documentId,
            null,
            null,
            null,
            null,
            undefined,
            undefined,
            undefined
          )
        ).items
      );

      this.setContentsLoaded(true);
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(
          e as ValidationProblemDetails,
          null,
          null,
          i18n.tc("documentModule.errorGettingContents"),
          true
        )
      );
    }
  }

  @Action
  public async loadContent() {
    if (this.contentId == null) {
      this.reset();
      return;
    }

    if (this.contentId != null && this.contentId == this.contentLoadedId)
      return;

    try {
      this.setContent(await this.contentsClient.get(this.contentId, undefined));

      this.setContentLoaded(true);
      this.setContentLoadedId(this.contentId);
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(
          e as ValidationProblemDetails,
          null,
          null,
          i18n.tc("documentModule.errorGettingContent"),
          true
        )
      );
    }
  }

  @Action
  public async loadTasks() {
    if (this.documentId == null) {
      this.reset();
      return;
    }

    try {
      this.setTasks(
        (
          await this.tasksClient.getWithPagination(
            this.documentId,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            undefined,
            undefined,
            undefined
          )
        ).items
      );

      this.setTaskLoaded(true);
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(
          e as ValidationProblemDetails,
          null,
          null,
          i18n.tc("documentModule.errorGettingTasks"),
          true
        )
      );
    }
  }

  @Action
  public async load(force = false) {
    if (force == true) {
      this.reset();
    }

    if (
      this.documentId == null ||
      this.document == null ||
      this.documentId != this.document.id ||
      this.documentLoaded == false
    ) {
      await this.loadDocument();
      this.resetContents();
      this.resetTasks();
    }

    if (this.contents == null || this.contentsLoaded == false) {
      await this.loadContents();
    }

    // loadContent здесь не вызываем

    if (this.tasks == null || this.tasksLoaded == false) {
      await this.loadTasks();
    }
  }

  @Action
  public async updateDocument() {
    if (this.documentId == null || this.document == null) {
      this.reset();
      return;
    }

    try {
      this.setDocumentUpdatingInProgress(true);

      await this.documentsClient.update(
        this.documentId,
        new UpdateDocumentCommand({
          id: this.document.id,
          userId: this.document.userId,
          date: this.document.date,
          medicalOrganizationName:
            this.document.medicalOrganizationName == "" ||
            this.document.medicalOrganizationName == undefined
              ? undefined
              : this.document.medicalOrganizationName,
          tags: this.document.tags,
        })
      );
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(
          e as ValidationProblemDetails,
          null,
          null,
          i18n.tc("documentModule.errorUpdatingDocument"),
          true
        )
      );
    } finally {
      this.setDocumentUpdatingInProgress(false);
    }
  }

  @Action
  public async processContentsPending() {
    if (this.documentId == null) {
      this.reset();
      return;
    }

    try {
      this.setContentsProcessPendingInProgress(true);

      for (const contents of this.contentsAddPending)
        await this.contentsClient.create(contents, this.documentId, undefined);

      for (const contents of this.contentsDeletePending)
        await this.contentsClient.delete(contents.id);
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(
          e as ValidationProblemDetails,
          null,
          null,
          i18n.tc("documentModule.errorProcessingContents"),
          true
        )
      );
    } finally {
      this.setContentsProcessPendingInProgress(false);
      this.resetContents();
      await this.loadContents();
    }
  }

  @Action
  public async disconnectTasksPending() {
    try {
      this.setTasksDisconnectingPendingInProgress(true);

      for (const task of this.tasksDisconnectingPending)
        await this.tasksClient.patch(task.id, [
          new Operation({
            op: OperationType.Replace,
            path: "documentId",
            value: null,
          }),
        ]);
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(
          e as ValidationProblemDetails,
          null,
          null,
          i18n.tc("documentModule.errorDeletingTasks"),
          true
        )
      );
    } finally {
      this.setTasksDisconnectingPendingInProgress(false);
      this.resetTasks();
      await this.loadTasks();
    }
  }

  @Action
  public async save() {
    await this.updateDocument();
    await this.disconnectTasksPending();
    await this.processContentsPending();
  }

  @Action
  public async deleteDocument() {
    if (this.documentId == null) {
      this.reset();
      return;
    }

    try {
      this.setDocumentDeletingInProgress(true);

      await this.documentsClient.delete(this.documentId, true);
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(
          e as ValidationProblemDetails,
          null,
          null,
          i18n.tc("documentModule.errorDeletingDocument"),
          true
        )
      );
    } finally {
      this.setDocumentDeletingInProgress(false);
      this.reset();
    }
  }
}
