import { ValidationProblemDetails } from "@/api-client";
import i18n from "@/i18n";

export default class ErrorsFromException {
  public error!: ValidationProblemDetails;
  public knownFields!: string[] | null;
  public putUnknownErrorsToField!: string | null;
  public unknownError: string = i18n.t("error.unknown") as string;
  public putUnknownErrorsToSnackBar = false;

  public constructor(
    error: ValidationProblemDetails,
    knownFields: string[] | null = null,
    putUnknownErrorsToField: string | null = null,
    unknownError: string = i18n.t("error.unknown") as string,
    putUnknownErrorsToSnackBar = false
  ) {
    this.error = error;
    this.knownFields = knownFields;
    this.putUnknownErrorsToField = putUnknownErrorsToField;
    this.unknownError = unknownError;
    this.putUnknownErrorsToSnackBar = putUnknownErrorsToSnackBar;
  }
}
