import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store, { DocumentStore, ErrorsStore } from "@/store";
import {
  DocumentDto,
  DocumentsClient,
  Operation,
  OperationType,
  TaskDto,
  TaskMeetingStatus,
  TasksClient,
  ValidationProblemDetails,
} from "@/api-client";
import router from "@/router";
import ErrorsFromException from "@/types/errorsFromException";
import i18n from "@/i18n";
import axios from "axios";

@Module({
  name: "taskView",
  namespaced: true,
  store,
})
export default class TaskViewModule extends VuexModule {
  public getTaskInProgress = false;
  public task = null as TaskDto | null;
  public document = null as DocumentDto | null;
  public taskLoaded = false;
  public documentLoaded = false;
  public taskDeletingInProgress = false;
  public taskWillComeInProcess = false;
  public taskWillNotComeInProcess = false;
  private taskId = null as string | null;
  private tasksClient = new TasksClient(undefined, axios);
  private documentsClient = new DocumentsClient();

  @Mutation
  public setTaskId(taskId: string) {
    this.taskId = taskId;
  }

  @Mutation
  public setTaskWillComeInProcess(taskWillComeInProcess: boolean) {
    this.taskWillComeInProcess = taskWillComeInProcess;
  }

  @Mutation
  public setTaskWillNotComeInProcess(taskWillNotComeInProcess: boolean) {
    this.taskWillNotComeInProcess = taskWillNotComeInProcess;
  }

  @Mutation
  public setTask(task: TaskDto) {
    this.task = task;
  }

  @Mutation
  public clearTask() {
    this.task = null;
  }

  @Mutation
  public setDocument(document: DocumentDto) {
    this.document = document;
  }

  @Mutation
  public clearDocument() {
    this.document = null;
  }

  @Mutation
  public setGetTaskInProgress(getTaskInProgress: boolean): void {
    this.getTaskInProgress = getTaskInProgress;
  }

  @Mutation
  public setTaskLoaded(taskLoaded: boolean) {
    this.taskLoaded = taskLoaded;
  }

  @Mutation
  public setDocumentLoaded(documentLoaded: boolean) {
    this.documentLoaded = documentLoaded;
  }

  @Mutation
  public setTaskDeletingInProgress(taskDeletingInProgress: boolean) {
    this.taskDeletingInProgress = taskDeletingInProgress;
  }

  @Mutation
  public setTaskViewedToTrue() {
    if (this.task != null) {
      this.task.viewed = true;
    }
  }

  @Mutation
  public setTaskMeetingStatusToConfirmed() {
    if (this.task != null) {
      this.task.meetingStatus = TaskMeetingStatus.Confirmed;
    }
  }

  @Mutation
  public setTaskMeetingStatusToCanceled() {
    if (this.task != null) {
      this.task.meetingStatus = TaskMeetingStatus.Canceled;
    }
  }

  @Action
  public async getTaskAndDocument(): Promise<void> {
    if (this.taskId == null) {
      console.log("Task id is empty");
      return;
    }

    await this.getTask();
    if (this.task != null) {
      await this.getDocument();
    }

    if (this.task?.viewed == false) {
      this.setTaskViewedToTrue();
      await this.tasksClient.patch(this.taskId, [
        new Operation({
          op: OperationType.Replace,
          path: "viewed",
          value: true,
        }),
      ]);
    }
  }

  @Action
  public async getTask(): Promise<void> {
    try {
      if (this.taskId == null) {
        console.log("Task id is empty");
        return;
      }

      this.setTask(await this.tasksClient.get(this.taskId));
      this.setTaskLoaded(true);
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(
          e as ValidationProblemDetails,
          null,
          null,
          i18n.t("tasksView.errorGettingTask") as string,
          true
        )
      );
    }
  }

  @Action
  async getDocument(): Promise<void> {
    if (this.task == null) {
      return;
    }

    if (this.task.documentId != null) {
      try {
        this.setDocument(await this.documentsClient.get(this.task.documentId));
        this.setDocumentLoaded(true);
      } catch (e) {
        ErrorsStore.setErrorsFromException(
          new ErrorsFromException(
            e as ValidationProblemDetails,
            null,
            null,
            i18n.t("tasksView.errorGettingDocument") as string,
            true
          )
        );
      }
    } else {
      this.setDocumentLoaded(true);
    }
  }

  @Action
  public async deleteTask(): Promise<void> {
    try {
      await this.setTaskDeletingInProgress(true);

      if (this.taskId == null) {
        console.log("Task id is empty");
        return;
      }

      await this.tasksClient.delete(this.taskId);
      await router.push({ name: "tasks" });
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(
          e as ValidationProblemDetails,
          null,
          null,
          i18n.t("tasksView.errorDeletingDocument") as string,
          true
        )
      );
    } finally {
      await this.setTaskDeletingInProgress(false);
      DocumentStore.resetTasks();
    }
  }

  @Action
  public async reset() {
    this.clearTask();
    this.clearDocument();
    this.setTaskLoaded(false);
    this.setDocumentLoaded(false);
    this.setTaskDeletingInProgress(false);
  }

  @Action
  public async willCome() {
    try {
      this.setTaskWillComeInProcess(true);

      if (this.taskId == null) {
        console.log("Task id is empty");
        return;
      }

      await this.tasksClient.patch(this.taskId, [
        new Operation({
          op: OperationType.Replace,
          path: "meetingStatus",
          value: TaskMeetingStatus.Confirmed,
        }),
      ]);

      this.setTaskMeetingStatusToConfirmed();
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(
          e as ValidationProblemDetails,
          null,
          null,
          i18n.t("tasksView.errorDeletingDocument") as string,
          true
        )
      );
    } finally {
      this.setTaskWillComeInProcess(false);
    }
  }

  @Action
  public async willNotCome() {
    try {
      this.setTaskWillNotComeInProcess(true);

      if (this.taskId == null) {
        console.log("Task id is empty");
        return;
      }

      await this.tasksClient.patch(this.taskId, [
        new Operation({
          op: OperationType.Replace,
          path: "meetingStatus",
          value: TaskMeetingStatus.Canceled,
        }),
      ]);

      this.setTaskMeetingStatusToCanceled();
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(
          e as ValidationProblemDetails,
          null,
          null,
          i18n.t("tasksView.errorDeletingDocument") as string,
          true
        )
      );
    } finally {
      this.setTaskWillNotComeInProcess(false);
    }
  }
}
