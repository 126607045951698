import { NotificationTokensStore } from "@/store";
import oneSignalOptionsValue from "@/types/oneSignal/oneSignalOptionsValue";

export default class OneSignalHelper {
  public static async start(oneSignal: unknown): Promise<void> {
    if (window.OneSignal == undefined || !window.OneSignal.config) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await oneSignal.init(oneSignalOptionsValue);
    }
    await this.wait();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await oneSignal.provideUserConsent(true);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await oneSignal.showSlidedownPrompt();
    await this.subscribe(oneSignal);
    this.showBell();
  }

  public static async onSubscriptionChange(oneSignal: unknown): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    oneSignal.on("subscriptionChange", async (isSubscribed: boolean) => {
      console.log("The user's subscription state is now:", isSubscribed);
      if (isSubscribed) {
        await this.subscribe(oneSignal);
      } else {
        await this.unsubscribe(oneSignal);
      }
    });
  }

  public static async subscribe(oneSignal: unknown): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!(await oneSignal.getSubscription())) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const userId = await oneSignal.getUserId();
    await NotificationTokensStore.create(userId);
  }

  public static async unsubscribe(oneSignal: unknown): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const userId = await oneSignal.getUserId();
    await NotificationTokensStore.delete(userId);
  }

  private static delay(ms: number): Promise<void> {
    return new Promise((res) => setTimeout(res, ms));
  }

  public static async wait(): Promise<void> {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (!window.OneSignal.config) {
        await this.delay(50);
      } else {
        return;
      }
    }
  }

  public static hideBell(): void {
    try {
      const link = document.getElementById("onesignal-bell-container");
      if (link != null) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        link.style.display = "none";
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        link.style.visibility = "hidden";
      }
    } catch (e) {
      console.log(e);
    }
  }

  public static showBell(): void {
    try {
      const link = document.getElementById("onesignal-bell-container");
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      link.style.display = "";
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      link.style.visibility = "";
    } catch (e) {
      console.log(e);
    }
  }
}
