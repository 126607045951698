import { render, staticRenderFns } from "./TrashIcon.vue?vue&type=template&id=2dee6046&scoped=true&"
import script from "./TrashIcon.vue?vue&type=script&lang=ts&"
export * from "./TrashIcon.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dee6046",
  null
  
)

export default component.exports