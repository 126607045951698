/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/no-unused-vars */
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store, { AuthStore, ErrorsStore } from "@/store";
import {
  CreatePhoneNumberVerificationConfirmationTokenCommand,
  CreatePhoneNumberVerificationTokenCommand,
  TokensClient,
  ValidationProblemDetails,
} from "@/api-client";
import router from "@/router";
import OneSignalHelper from "@/types/oneSignal/oneSignalHelper";
// noinspection ES6UnusedImports
import OneSignalVue from "onesignal-vue";
import ErrorsFromException from "@/types/errorsFromException";

@Module({
  name: "registrationConfirmation",
  namespaced: true,
  store,
})
export default class RegistrationConfirmationModule extends VuexModule {
  private readonly tokensClient = new TokensClient() as TokensClient;
  public verificationInProgress = true;
  public verificationConfirmationInProgress = false;

  @Mutation
  public setVerificationInProgress(verificationInProgress: boolean): void {
    this.verificationInProgress = verificationInProgress;
  }

  @Mutation
  public setVerificationConfirmationInProgress(
    verificationConfirmationInProgress: boolean
  ): void {
    this.verificationConfirmationInProgress =
      verificationConfirmationInProgress;
  }

  @Action
  public async createPhoneNumberVerification(
    createPhoneNumberVerificationTokenCommand: CreatePhoneNumberVerificationTokenCommand
  ): Promise<void> {
    this.setVerificationInProgress(true);
    try {
      const result = await this.tokensClient.createPhoneNumberVerification(
        createPhoneNumberVerificationTokenCommand
      );
      if (result.value != undefined) {
        alert(result.value);
      }
      this.setVerificationInProgress(false);
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(e as ValidationProblemDetails)
      );
      this.setVerificationInProgress(false);
    }
  }

  @Action
  public async createPhoneNumberVerificationConfirmation(
    createPhoneNumberVerificationConfirmationTokenCommand: CreatePhoneNumberVerificationConfirmationTokenCommand
  ): Promise<void> {
    this.setVerificationConfirmationInProgress(true);
    try {
      await this.tokensClient.createPhoneNumberVerificationConfirmation(
        createPhoneNumberVerificationConfirmationTokenCommand
      );
      await AuthStore.getUserId();
      // noinspection ES6MissingAwait
      OneSignalHelper.start((this as any).store._vm.$OneSignal);
      this.setVerificationConfirmationInProgress(false);
      await router.push({ name: "registration-password" });
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(e as ValidationProblemDetails)
      );
      this.setVerificationConfirmationInProgress(false);
    }
  }
}
