import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store, { ErrorsStore } from "@/store";
import {
  CreatePasswordResetTokenCommand,
  CreateVerificationCommand,
  TokensClient,
  ValidationProblemDetails,
} from "@/api-client";
import router from "@/router";
import ErrorsFromException from "@/types/errorsFromException";

@Module({
  name: "passwordRecoveryConfirmation",
  namespaced: true,
  store,
})
export default class PasswordRecoveryConfirmationModule extends VuexModule {
  private readonly tokensClient = new TokensClient() as TokensClient;
  public verificationInProgress = false;
  public verificationConfirmationInProgress = false;

  @Mutation
  public setVerificationInProgress(verificationInProgress: boolean): void {
    this.verificationInProgress = verificationInProgress;
  }

  @Mutation
  public setVerificationConfirmationInProgress(
    verificationConfirmationInProgress: boolean
  ): void {
    this.verificationConfirmationInProgress =
      verificationConfirmationInProgress;
  }

  @Action
  public async createPasswordReset(
    createPasswordResetTokenCommand: CreatePasswordResetTokenCommand
  ): Promise<void> {
    this.setVerificationInProgress(true);
    try {
      const result = await this.tokensClient.createPasswordReset(
        createPasswordResetTokenCommand
      );
      if (result.value != undefined) {
        alert(result.value);
      }
      this.setVerificationInProgress(false);
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(e as ValidationProblemDetails)
      );
      this.setVerificationInProgress(false);
    }
  }

  @Action
  public async createVerification(
    createVerificationCommand: CreateVerificationCommand
  ): Promise<void> {
    this.setVerificationConfirmationInProgress(true);
    try {
      await this.tokensClient.createVerification(createVerificationCommand);
      await router.push({ name: "password-recovery-password" });
      localStorage.setItem(
        "password-recovery-token",
        createVerificationCommand.token
      );
    } catch (e) {
      console.log(e);
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(e as ValidationProblemDetails)
      );
      this.setVerificationConfirmationInProgress(false);
    }
  }
}
