import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { AuthClient } from "@/api-client";

@Module({
  name: "auth",
  namespaced: true,
  store,
})
export default class AuthModule extends VuexModule {
  private authClient = new AuthClient() as AuthClient;
  public userId = null as string | null;

  @Mutation
  public setUserId(userId: string | null): void {
    this.userId = userId;
  }

  @Action
  public async logout(): Promise<void> {
    try {
      await this.authClient.logout();
    } catch (e) {
      console.log(e);
    }
  }

  @Action
  public async getUserId(): Promise<string | null> {
    try {
      const userId = await this.authClient.getUserId();
      this.setUserId(userId);
      return userId;
    } catch (e) {
      console.log(e);
      this.setUserId(null);
      return null;
    }
  }
}
