/* eslint-disable @typescript-eslint/no-unused-vars,@typescript-eslint/no-explicit-any */
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store, { AuthStore, ErrorsStore } from "@/store";
import {
  CreatePasswordResetConfirmationTokenCommand,
  TokensClient,
  ValidationProblemDetails,
} from "@/api-client";
import router from "@/router";
import OneSignalHelper from "@/types/oneSignal/oneSignalHelper";
// noinspection ES6UnusedImports
import OneSignalVue from "onesignal-vue";
import ErrorsFromException from "@/types/errorsFromException";

@Module({
  name: "passwordRecoveryPassword",
  namespaced: true,
  store,
})
export default class PasswordRecoveryPasswordModule extends VuexModule {
  private tokensClient = new TokensClient() as TokensClient;
  public createPasswordInProgress = false;

  @Mutation
  public setCreatePasswordResetConfirmationInProgress(
    createPasswordResetConfirmationInProgress: boolean
  ): void {
    this.createPasswordInProgress = createPasswordResetConfirmationInProgress;
  }

  @Action
  public async createPasswordResetConfirmation(
    createPasswordResetConfirmationTokenCommand: CreatePasswordResetConfirmationTokenCommand
  ): Promise<void> {
    this.setCreatePasswordResetConfirmationInProgress(true);
    try {
      await this.tokensClient.createPasswordResetConfirmation(
        createPasswordResetConfirmationTokenCommand
      );
      await AuthStore.getUserId();
      // noinspection ES6MissingAwait
      OneSignalHelper.start((this as any).store._vm.$OneSignal);
      this.setCreatePasswordResetConfirmationInProgress(false);
      await router.push({ name: "documents" });
      return;
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(e as ValidationProblemDetails, [
          "password",
          "passwordConfirmation",
        ])
      );
      this.setCreatePasswordResetConfirmationInProgress(false);
      return;
    }
  }
}
