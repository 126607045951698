import { Action, Module, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import {
  AnonymousDocumentContentsClient,
  AnonymousDocumentDto,
  AnonymousDocumentsClient,
  FileResponse,
} from "@/api-client";

@Module({
  name: "anonymousDocuments",
  namespaced: true,
  store,
})
export default class AnonymousDocumentsModule extends VuexModule {
  private anonymousDocumentsClient =
    new AnonymousDocumentsClient() as AnonymousDocumentsClient;
  private anonymousDocumentContentsClient =
    new AnonymousDocumentContentsClient() as AnonymousDocumentContentsClient;

  @Action
  public async get(id: string): Promise<AnonymousDocumentDto | null> {
    try {
      return await this.anonymousDocumentsClient.get(id);
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  @Action
  public async getContent(id: string): Promise<FileResponse | null> {
    try {
      const documentContents =
        await this.anonymousDocumentContentsClient.getWithPagination(
          id,
          null,
          null,
          null,
          1,
          1
        );
      if (
        documentContents.items == undefined ||
        documentContents.items.length == 0 ||
        documentContents.items[0].id == undefined
      ) {
        return null;
      }

      return await this.anonymousDocumentContentsClient.get(
        documentContents.items[0].id
      );
    } catch (e) {
      console.log(e);
      return null;
    }
  }
}
