export default class OneSignalWelcomeNotificationOptions {
  constructor(data?: OneSignalWelcomeNotificationOptions) {
    if (data) {
      for (const property in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty(property))
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  // Disables sending a welcome notification to new site visitors.
  // If you want to disable welcome notifications, this is the only option you need.
  public disable!: boolean;

  // The welcome notification's title.
  // You can localize this to your own language.
  // If not set, or left blank, the site's title will be used.
  // Set to one space ' ' to clear the title, although this is not recommended.
  public title!: string;

  // Required: The welcome notification's message.
  // You can localize this to your own language.
  // A message is required.
  // If left blank or set to blank, the default of 'Thanks for subscribing!' will be used.
  public message!: string;

  // URL An optional URL to open after the user clicks the welcome notification.
  //  By default, clicking the welcome notification does not open any link.
  //  This is recommended because the user has just visited your site and subscribed.
  public url!: string;
}
