/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store, { AuthStore, ErrorsStore } from "@/store";
import {
  AddressesClient,
  CertificatesClient,
  CertificateStatus,
  CertificateType,
  CreateCertificateCommand,
  OrganizationDto,
  OrganizationsClient,
  ValidationProblemDetails,
} from "@/api-client";
import router from "@/router";
import ErrorsFromException from "@/types/errorsFromException";
import i18n from "@/i18n";

@Module({
  name: "certificateCreate",
  namespaced: true,
  store,
})
export default class CertificateCreateModule extends VuexModule {
  private certificatesClient = new CertificatesClient();
  private organizationsClient = new OrganizationsClient();
  private addressesClient = new AddressesClient();

  // Certificate fields

  // type
  public type = CertificateType.EpidemiologicalWellBeing;

  @Mutation
  public setType(type: CertificateType) {
    this.type = type;
  }

  public types = [
    {
      text: i18n.tc("certificateCreate.epidemiologicalWellBeing"),
      value: CertificateType.EpidemiologicalWellBeing,
    },
  ];

  // medical organization id

  public medicalOrganizationId = null as string | null;

  @Mutation
  public setMedicalOrganizationId(medicalOrganizationId: string | null) {
    this.medicalOrganizationId = medicalOrganizationId;
  }

  public medicalOrganizations = [] as OrganizationDto[];

  @Mutation
  public setMedicalOrganizations(medicalOrganizations: OrganizationDto[]) {
    this.medicalOrganizations = medicalOrganizations;
  }

  public medicalOrganizationsInProgress = false;

  @Mutation
  public setMedicalOrganizationsInProgress(
    medicalOrganizationsInProgress: boolean
  ) {
    this.medicalOrganizationsInProgress = medicalOrganizationsInProgress;
  }

  // fullname

  public fullName = null as string | null;

  @Mutation
  public setFullName(fullName: string | null) {
    this.fullName = fullName;
  }

  // institutionId

  public institutionId = null as string | null;

  @Mutation
  public setInstitutionId(institutionId: string | null) {
    this.institutionId = institutionId;
  }

  public institutions = [] as OrganizationDto[];

  @Mutation
  public setInstitutions(institutions: OrganizationDto[]) {
    this.institutions = institutions;
  }

  public institutionsInProgress = false;

  @Mutation
  public setInstitutionsInProgress(institutionsInProgress: boolean) {
    this.institutionsInProgress = institutionsInProgress;
  }

  // address

  public address = null as string | null;

  @Mutation
  public setAddress(address: string | null) {
    this.address = address;
  }

  public addresses = [] as string[];

  @Mutation
  public setAddresses(addresses: string[]) {
    this.addresses = addresses;
  }

  public addressesInProgress = false;

  @Mutation
  public setAddressesInProgress(addressesInProgress: boolean) {
    this.addressesInProgress = addressesInProgress;
  }

  // helpers

  public createCertificateInProgress = false;

  @Mutation
  public setCreateCertificateInProgress(
    createCertificateInProgress: boolean
  ): void {
    this.createCertificateInProgress = createCertificateInProgress;
  }

  @Action
  public async create() {
    try {
      this.setCreateCertificateInProgress(true);
      const createCertificateCommand = new CreateCertificateCommand({
        type: this.type,
        medicalOrganizationId: this.medicalOrganizationId!,
        fullName: this.fullName!,
        address: this.address!,
        institutionId: this.institutionId!,
        issueDate: undefined,
        userId: AuthStore.userId!,
        status: CertificateStatus.Sent,
        comment: undefined,
      });
      const certificate = await this.certificatesClient.create(
        createCertificateCommand
      );
      await router.push({
        name: "certificates-view",
        params: { certificateId: certificate.id },
      });
      await this.reset();
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(
          e as ValidationProblemDetails,
          [
            "type",
            "medicalOrganizationId",
            "fullName",
            "address",
            "institutionId",
          ],
          null,
          i18n.t("certificateCreate.errorCreatingCertificate") as string,
          true
        )
      );
    } finally {
      this.setCreateCertificateInProgress(false);
    }
  }

  @Action
  public async updateMedicalOrganizations(search: string | null) {
    try {
      this.setMedicalOrganizationsInProgress(true);
      const paginatedListOfOrganizationDto =
        await this.organizationsClient.getWithPagination(
          null,
          search,
          null,
          true,
          null,
          1,
          20,
          false,
          ["ShortName:asc"]
        );
      this.setMedicalOrganizations(paginatedListOfOrganizationDto.items);
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(
          e as ValidationProblemDetails,
          null,
          null,
          i18n.tc("certificateCreate.medicalOrganizationsError"),
          true
        )
      );
    } finally {
      this.setMedicalOrganizationsInProgress(false);
    }
  }

  @Action
  public async updateInstitutions(search: string | null) {
    try {
      this.setInstitutionsInProgress(true);
      const paginatedListOfOrganizationDto =
        await this.organizationsClient.getWithPagination(
          null,
          search,
          null,
          null,
          true,
          1,
          20,
          false,
          ["ShortName:asc"]
        );
      this.setInstitutions(paginatedListOfOrganizationDto.items);
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(
          e as ValidationProblemDetails,
          null,
          null,
          i18n.tc("certificateCreate.institutionsError"),
          true
        )
      );
    } finally {
      this.setInstitutionsInProgress(false);
    }
  }

  @Action
  public async updateAddresses(search: string | null) {
    try {
      this.setAddressesInProgress(true);
      const addresses = await this.addressesClient.getList(search);
      this.setAddresses(addresses);
    } catch (e) {
      // ignore
    } finally {
      this.setAddressesInProgress(false);
    }
  }

  @Action
  public async reset() {
    this.setType(CertificateType.EpidemiologicalWellBeing);
    this.setMedicalOrganizationId(null);
    this.setMedicalOrganizationsInProgress(false);
    this.setFullName(null);
    this.setInstitutionId(null);
    this.setInstitutionsInProgress(false);
    this.setAddress(null);
    this.setAddressesInProgress(false);
    this.setCreateCertificateInProgress(false);
  }
}
