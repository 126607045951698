import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import {
  CertificateDto,
  CertificatesClient,
  CertificateStatus,
  OrganizationDto,
  OrganizationsClient,
} from "@/api-client";
import AsyncLock from "async-lock";

@Module({
  name: "certificate",
  namespaced: true,
  store,
})
export default class CertificateModule extends VuexModule {
  public searchText = null as string | null | undefined;
  public hasNextPage = true;
  public getCertificatesInProgress = false;
  public certificates = [] as CertificateDto[];
  public category = 0 as number;
  private pageNumber = 1;
  private pageSize = 15;
  private certificatesClient = new CertificatesClient();
  private tasksLoadingLock = "";
  private asyncLock = new AsyncLock();
  private changeTaskStatusAsyncLock = new AsyncLock();
  public organizations = [] as OrganizationDto[];
  private organizationsClient = new OrganizationsClient();

  @Mutation
  public addCertificate(certificateDto: CertificateDto): void {
    this.certificates.push(certificateDto);
  }

  @Mutation
  public resetCertificates(): void {
    this.certificates = [];
  }

  @Mutation
  public removeCertificate(id: string): void {
    const certificatePosition = this.certificates.map((x) => x.id).indexOf(id);
    this.certificates.splice(certificatePosition, 1);
  }

  @Mutation
  public setSearchText(searchText: string | null | undefined) {
    this.searchText = searchText;
  }

  @Mutation
  public setCategory(category: number) {
    this.category = category;
  }

  @Mutation
  public resetPageNumber() {
    this.pageNumber = 1;
  }

  @Mutation
  public incrementPageNumber() {
    this.pageNumber++;
  }

  @Mutation
  public setHasNextPage(hasNextPage: boolean) {
    this.hasNextPage = hasNextPage;
  }

  @Mutation
  public setGetCertificatesInProgress(
    getCertificatesInProgress: boolean
  ): void {
    this.getCertificatesInProgress = getCertificatesInProgress;
  }

  @Mutation
  public setOrganizations(organizations: OrganizationDto[]): void {
    this.organizations = organizations;
  }

  @Action
  public async resetAndLoadMoreCertificates() {
    this.resetPageNumber();
    this.setHasNextPage(true);
    await this.loadMoreCertificates();
  }

  @Action
  public async loadMoreCertificates() {
    await this.asyncLock.acquire(this.tasksLoadingLock, async () => {
      if (!this.hasNextPage) {
        return;
      }

      this.setGetCertificatesInProgress(true);

      const pageNumber = this.pageNumber;
      this.incrementPageNumber();

      const paginatedListOfCertificateDto =
        await this.certificatesClient.getWithPagination(
          null,
          null,
          null,
          this.searchText,
          this.category == 0
            ? undefined
            : this.category == 1
            ? [CertificateStatus.Rejected, CertificateStatus.Error]
            : [CertificateStatus.Sent, CertificateStatus.Received],
          null,
          null,
          ["date:desc", "created:desc"],
          pageNumber,
          this.pageSize,
          undefined
        );

      if (pageNumber == 1) {
        this.resetCertificates();
      }

      for (const newTask of paginatedListOfCertificateDto.items) {
        this.addCertificate(newTask);
      }

      this.setHasNextPage(paginatedListOfCertificateDto.hasNextPage);
      this.setGetCertificatesInProgress(false);
    });
  }

  @Action
  public async updateOrganizations() {
    try {
      const paginatedListOfOrganizationDto =
        await this.organizationsClient.getWithPagination(
          null,
          null,
          null,
          true,
          null,
          1,
          1000,
          false,
          null
        );
      this.setOrganizations(paginatedListOfOrganizationDto.items);
    } catch (e) {
      console.log(e);
    }
  }
}
