import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store, { ErrorsStore } from "@/store";
import {
  CertificateContentsClient,
  FileResponse,
  ValidationProblemDetails,
} from "@/api-client";
import ErrorsFromException from "@/types/errorsFromException";
import i18n from "@/i18n";
import fileDownload from "js-file-download";

@Module({
  name: "certificateContentView",
  namespaced: true,
  store,
})
export default class CertificateContentViewModule extends VuexModule {
  private certificateContentsClient = new CertificateContentsClient();

  // certificate content id

  public contentId = null as string | null;

  @Mutation
  public setContentId(contentId: string | null): void {
    this.contentId = contentId;
  }

  // content

  public content = null as FileResponse | null;

  @Mutation
  public setContent(content: FileResponse | null) {
    this.content = content;
  }

  // content url

  public contentUrl = null as string | null;

  @Mutation
  public setContentUrl(contentUrl: string | null) {
    this.contentUrl = contentUrl;
  }

  // download

  public downloadInProcess = false;

  @Mutation
  public setDownloadInProcess(downloadInProcess: boolean) {
    this.downloadInProcess = downloadInProcess;
  }

  @Action
  public async getContent() {
    if (this.contentId == null) {
      console.log("Certificate content id is null");
      return;
    }

    try {
      const result = await this.certificateContentsClient.get(this.contentId);
      this.setContent(result);
      this.setContentUrl(URL.createObjectURL(result.data));
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(
          e as ValidationProblemDetails,
          null,
          null,
          i18n.tc("certificateContentView.errorGettingContent"),
          true
        )
      );
    }
  }

  @Action
  public async download() {
    if (this.content == null || this.fileName == null) {
      console.log("Content or filename is null");
      return;
    }

    this.setDownloadInProcess(true);
    fileDownload(this.content.data, this.fileName);
    this.setDownloadInProcess(false);
  }

  @Action
  public async reset() {
    this.setContentId(null);
    this.setContent(null);

    try {
      if (this.contentUrl != null) {
        URL.revokeObjectURL(this.contentUrl);
      }
    } catch (e) {
      // ignore
    }

    this.setContentUrl(null);
    this.setDownloadInProcess(false);
  }

  public get fileName(): string | null {
    console.log(this.content?.fileName);
    if (this.content?.fileName == null) {
      return "file.pdf";
    }
    return decodeURI(this.content.fileName);
  }

  public get pdf(): boolean {
    if (this.fileName == null) {
      return false;
    }

    return this.fileName.endsWith(".pdf");
  }
}
