import {
  PartnersClient,
  SendMessageCommand,
  ValidationProblemDetails,
} from "@/api-client";
import i18n from "@/i18n";
import store, { ErrorsStore } from "@/store";
import ErrorsFromException from "@/types/errorsFromException";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({
  name: "partners",
  namespaced: true,
  store,
})
export default class PartnersModule extends VuexModule {
  private partnersClient = new PartnersClient();
  public text = "";
  public sendMessageInProgress = false;
  public dialog = false;

  @Mutation
  public setText(text: string) {
    this.text = text;
  }

  @Mutation
  public setDialog(dialog: boolean) {
    this.dialog = dialog;
  }

  @Mutation
  public setSendMessageInProgress(sendMessageInProgress: boolean) {
    this.sendMessageInProgress = sendMessageInProgress;
  }

  @Action
  public async sendMessage(): Promise<void> {
    try {
      this.setSendMessageInProgress(true);

      await this.partnersClient.sendMessage(
        new SendMessageCommand({ text: this.text })
      );
      this.setDialog(true);
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(
          e as ValidationProblemDetails,
          null,
          null,
          i18n.tc("partners.errorText"),
          true
        )
      );
    } finally {
      this.setSendMessageInProgress(false);
    }
  }

  @Action
  public reset() {
    this.setText("");
    this.setDialog(false);
  }
}
