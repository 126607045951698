import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import i18n from "@/i18n";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/i",
    name: "i",
    components: {
      default: () => import("@/views/Invitation.vue"),
    },
  },
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/registration-phone",
    name: "registration-phone",
    component: () => import("@/views/RegistrationPhone.vue"),
  },
  {
    path: "/registration-confirmation",
    name: "registration-confirmation",
    component: () => import("@/views/RegistrationConfirmation.vue"),
  },
  {
    path: "/registration-password",
    name: "registration-password",
    component: () => import("@/views/RegistrationPassword.vue"),
  },
  {
    path: "/registration-profile",
    name: "registration-profile",
    component: () => import("@/views/RegistrationProfile.vue"),
  },
  {
    path: "/password-recovery-phone",
    name: "password-recovery-phone",
    component: () => import("@/views/PasswordRecoveryPhone.vue"),
  },
  {
    path: "/password-recovery-password",
    name: "password-recovery-password",
    component: () => import("@/views/PasswordRecoveryPassword.vue"),
  },
  {
    path: "/password-recovery-confirmation",
    name: "password-recovery-confirmation",
    component: () => import("@/views/PasswordRecoveryConfirmation.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    components: {
      default: () => import("@/views/Profile.vue"),
      bottom: () => import("@/components/BottomNavigation.vue"),
    },
  },
  {
    path: "/documents/create",
    name: "documents-create",
    components: {
      default: () => import("@/views/DocumentsCreate.vue"),
      bottom: () => import("@/components/BottomNavigation.vue"),
    },
  },
  {
    path: "/documents/create/tags",
    name: "documents-create-tags",
    components: {
      default: () => import("@/views/DocumentsCreateTags.vue"),
    },
  },
  {
    path: "/documents",
    name: "documents",
    components: {
      default: () => import("@/views/Documents.vue"),
      bottom: () => import("@/components/BottomNavigation.vue"),
    },
  },
  {
    path: "/documents/:documentId/view",
    name: "documents-view",
    components: {
      default: () => import("@/views/DocumentsView.vue"),
    },
  },
  {
    path: "/documents/:documentId/view/content/:contentId",
    name: "documents-view-content",
    components: {
      default: () => import("@/views/DocumentsViewContent.vue"),
    },
  },
  {
    path: "/documents/:documentId/view/tags",
    name: "documents-view-tags",
    components: {
      default: () => import("@/views/DocumentsTags.vue"),
    },
    props: { default: { updateImmediately: true } },
  },
  {
    path: "/documents/:documentId/edit",
    name: "documents-edit",
    components: {
      default: () => import("@/views/DocumentsEdit.vue"),
    },
  },
  {
    path: "/documents/:documentId/edit/tags",
    name: "documents-edit-tags",
    components: {
      default: () => import("@/views/DocumentsTags.vue"),
    },
    props: { default: { updateImmediately: false } },
  },
  {
    path: "/feedback",
    name: "feedback",
    components: {
      default: () => import("@/views/Feedback.vue"),
      bottom: () => import("@/components/BottomNavigation.vue"),
    },
  },
  {
    path: "/privacy",
    name: "privacy",
    components: {
      default: () => import("@/views/Privacy.vue"),
    },
  },
  {
    path: "/tasks/create",
    name: "tasks-create",
    components: {
      default: () => import("@/views/tasks/TasksCreate.vue"),
    },
  },
  {
    path: "/tasks",
    name: "tasks",
    components: {
      default: () => import("@/views/tasks/Tasks.vue"),
      bottom: () => import("@/components/BottomNavigation.vue"),
    },
  },
  {
    path: "/tasks/:taskId/view",
    name: "tasks-view",
    components: {
      default: () => import("@/views/tasks/TasksView.vue"),
    },
  },
  {
    path: "/tasks/:taskId/edit",
    name: "tasks-edit",
    components: {
      default: () => import("@/views/tasks/TasksEdit.vue"),
    },
  },
  {
    path: "/partners",
    name: "partners",
    components: {
      default: () => import("@/views/Partners.vue"),
    },
  },
  {
    path: "/certificates",
    name: "certificates",
    components: {
      default: () => import("@/views/certificates/Certificates.vue"),
      bottom: () => import("@/components/BottomNavigation.vue"),
    },
  },
  {
    path: "/certificates/create",
    name: "certificates-create",
    components: {
      default: () => import("@/views/certificates/CertificatesCreate.vue"),
    },
  },
  {
    path: "/certificates/:certificateId/view",
    name: "certificates-view",
    components: {
      default: () => import("@/views/certificates/CertificatesView.vue"),
    },
  },
  {
    path: "/certificates/:certificateId/content/:contentId/view",
    name: "certificates-content-view",
    components: {
      default: () => import("@/views/certificates/CertificatesContentView.vue"),
    },
  },
  {
    path: "/:code",
    name: "documents-link-view",
    components: {
      default: () => import("@/views/DocumentsLinkView.vue"),
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title =
    `${i18n.t("title" as string)} - ${i18n.t(
      ("pageTitles." + to.name) as string
    )}` || `${i18n.t("title" as string)}`;
  next();
});

export default router;
