import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store, { ErrorsStore } from "@/store";
import {
  CreateUserPasswordCommand,
  UsersClient,
  ValidationProblemDetails,
} from "@/api-client";
import router from "@/router";
import ErrorsFromException from "@/types/errorsFromException";

@Module({
  name: "registrationPassword",
  namespaced: true,
  store,
})
export default class RegistrationPasswordModule extends VuexModule {
  private usersClient = new UsersClient() as UsersClient;
  public createPasswordInProgress = false;

  @Mutation
  public setCreatePasswordInProgress(createPasswordInProgress: boolean): void {
    this.createPasswordInProgress = createPasswordInProgress;
  }

  @Action
  public async create(
    createUserPasswordCommand: CreateUserPasswordCommand
  ): Promise<void> {
    this.setCreatePasswordInProgress(true);
    try {
      await this.usersClient.createPassword(
        createUserPasswordCommand.id,
        createUserPasswordCommand
      );
      this.setCreatePasswordInProgress(false);
      await router.push({ name: "registration-profile" });
      return;
    } catch (e) {
      ErrorsStore.setErrorsFromException(
        new ErrorsFromException(e as ValidationProblemDetails, [
          "password",
          "passwordConfirmation",
        ])
      );
      this.setCreatePasswordInProgress(false);
      return;
    }
  }
}
