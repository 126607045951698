export default class OneSignalNotifyButtonOptions {
  constructor(data?: OneSignalNotifyButtonOptions) {
    if (data) {
      for (const property in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty(property))
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  // Enable the Subscription Bell. The Subscription Bell is otherwise disabled by default.
  public enable!: boolean;

  // A function you define that returns true to show the Subscription Bell, or false to hide it.
  // You can also return a Promise that resolves to true or false for awaiting asynchronous operations.
  // Typically used the hide the Subscription Bell after the user is subscribed.
  // This function is not re-evaluated on every state change;
  // this function is only evaluated once when the Subscription Bell begins to show.
  // See Hiding the Subscription Bell for an example.
  // https://documentation.onesignal.com/docs/web-push-custom-code-examples#hiding-the-subscription-bell
  public displayPredicate!: () => boolean;

  // One of 'small', 'medium', or 'large'.
  // The Subscription Bell will initially appear at one of these sizes, and then shrink down to size 'small' after the user subscribes.
  public size!: string;

  // Either 'bottom-left' or 'bottom-right'.
  // The Subscription Bell will be fixed at this location on your page.
  public position!: string;

  // Specify CSS-valid pixel offsets using bottom, left, and right.
  public offset!: unknown;

  // If true, the Subscription Bell will display an icon that there is 1 unread message.
  // When hovering over the Subscription Bell, the user will see custom text set by message.prenotify.
  public prenotify!: boolean;

  // Set false to hide the 'Powered by OneSignal' text in the Subscription Bell dialog popup.
  public showCredit!: boolean;

  // Customize the Subscription Bell text. See the example code below to know what to change.
  public text!: unknown;
}
