export default class PageCollector {
  public async getAllPagesContent<T>(
    fn: ({
      pageNumber,
      pageSize,
    }: {
      pageNumber: number;
      pageSize: number;
    }) => Promise<{
      items: T[] | undefined;
      totalPages: number | undefined;
    }>
  ): Promise<T[]> {
    const children = new Array<T>();

    const pageParams = { pageNumber: 1, pageSize: 100 } as {
      pageNumber: number;
      pageSize: number;
    };

    const firstBatch = await fn(pageParams);

    if (firstBatch.items == undefined) {
      return [];
    }

    for (const item of firstBatch.items) {
      children.push(item);
    }

    if (firstBatch.totalPages == undefined) {
      return children;
    }

    for (
      pageParams.pageNumber = 2;
      pageParams.pageNumber <= firstBatch.totalPages;
      pageParams.pageNumber++
    ) {
      const batch = await fn(pageParams);

      if (batch.items == undefined) {
        return children;
      }

      for (const item of batch.items) {
        children.push(item);
      }
    }

    return children;
  }
}
