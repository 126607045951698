import { DocumentDto, DocumentsClient } from "@/api-client";
import store from "@/store";
import AsyncLock from "async-lock";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({
  name: "documents",
  namespaced: true,
  store,
})
export default class DocumentsModule extends VuexModule {
  public searchText = null as string | null | undefined;
  public hasNextPage = true;
  public getDocumentsInProgress = false;
  public documents = [] as DocumentDto[];
  private pageNumber = 1;
  private pageSize = 15;
  private documentsClient = new DocumentsClient();
  private documentsLoadingLock = "";
  private asyncLock = new AsyncLock();
  public search = false;

  @Mutation
  public switchSearch() {
    this.search = !this.search;
  }

  @Mutation
  public setSearch(search: boolean) {
    this.search = search;
  }

  @Mutation
  public addDocument(documentDto: DocumentDto): void {
    this.documents.push(documentDto);
  }

  @Mutation
  public resetDocuments(): void {
    this.documents = [];
  }

  @Mutation
  public setSearchText(searchText: string | null | undefined) {
    this.searchText = searchText;
  }

  @Mutation
  public resetPageNumber() {
    this.pageNumber = 1;
  }

  @Mutation
  public incrementPageNumber() {
    this.pageNumber++;
  }

  @Mutation
  public setHasNextPage(hasNextPage: boolean) {
    this.hasNextPage = hasNextPage;
  }

  @Mutation
  public setGetDocumentsInProgress(createDocumentsInProgress: boolean): void {
    this.getDocumentsInProgress = createDocumentsInProgress;
  }

  @Action
  public async reset() {
    this.setSearch(false);
    this.resetPageNumber();
    this.setSearchText(null);
    this.setHasNextPage(true);
    this.resetDocuments();
  }

  @Action
  public async resetAndLoadMoreDocuments() {
    this.resetPageNumber();
    this.setHasNextPage(true);
    await this.loadMoreDocuments();
  }

  @Action
  public async loadMoreDocuments() {
    await this.asyncLock.acquire(this.documentsLoadingLock, async () => {
      if (!this.hasNextPage) {
        return;
      }

      this.setGetDocumentsInProgress(true);

      const pageNumber = this.pageNumber;
      //console.log(pageNumber);
      this.incrementPageNumber();

      const paginatedListOfDocumentDto =
        await this.documentsClient.getWithPagination(
          null,
          this.searchText,
          null,
          null,
          ["date:desc", "created:desc"],
          pageNumber,
          this.pageSize
        );

      if (pageNumber == 1) {
        this.resetDocuments();
      }

      for (const newDocument of paginatedListOfDocumentDto.items) {
        this.addDocument(newDocument);
      }

      this.setHasNextPage(paginatedListOfDocumentDto.hasNextPage);
      this.setGetDocumentsInProgress(false);
    });
  }
}
