import OneSignalPromptOptions from "@/types/oneSignal/oneSignalPromptOptions";
import OneSignalWelcomeNotificationOptions from "@/types/oneSignal/oneSignalWelcomeNotificationOptions";
import OneSignalNotifyButtonOptions from "@/types/oneSignal/oneSignalNotifyButtonOptions";

export default class OneSignalOptions {
  constructor(data?: OneSignalOptions) {
    if (data) {
      for (const property in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty(property))
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  // Required: Your OneSignal app id found on the settings page at onesignal.com.
  public appId!: string;

  public safari_web_id!: string;

  // Required on HTTP ONLY: This must match the label you entered in Site Settings.
  // https://documentation.onesignal.com/docs/web-push-custom-code-setup#my-site-is-not-fully-https
  public subdomainName!: string;

  // Allows you to delay the initialization of the SDK until the user provides privacy consent.
  // The SDK will not be fully initialized until the provideUserConsent function is called.
  // https://documentation.onesignal.com/docs/web-push-sdk#provideuserconsent-method
  public requiresUserPrivacyConsent!: boolean;

  // Localize the HTTP popup prompt. See below details.
  // https://documentation.onesignal.com/docs/web-push-sdk#init-promptoptions-parameters
  public promptOptions!: OneSignalPromptOptions;

  // Customize or disable the welcome notification sent to new site visitors.
  // See below details.
  // https://documentation.onesignal.com/docs/web-push-sdk#init-welcomenotification-parameters
  public welcomeNotification!: OneSignalWelcomeNotificationOptions;

  // Enable and customize the notifyButton.
  // See below details.
  // https://documentation.onesignal.com/docs/web-push-sdk#init-notifybutton-parameters
  public notifyButton!: OneSignalNotifyButtonOptions;

  // Chrome (non-mobile) - true: persists notification, false: disappears after some time.
  // See our Notification Persistence Section for more info.
  // https://documentation.onesignal.com/docs/web-push-options#notification-persistence
  // Firefox and Safari - notifications automatically dismiss after some time and this parameter does not control that.
  public persistNotification!: boolean;

  // See our Webhooks page for the nested options.
  // https://documentation.onesignal.com/docs/webhooks
  public webhooks!: unknown;

  // Recommended, HTTPS ONLY - Automatically resubscribes users when they clear browser cache or migrating to OneSignal.
  // This is set in the OneSignal dashboard during setup but if set again during initialization, will override dashboard config.
  public autoResubscribe!: boolean;

  // Not Recommended: Shows Native Browser Prompt immediately.
  // We do not recommend using because it creates a poor user experience.
  // Should be removed from code.
  public autoRegister!: boolean;

  // Default: If the launch URL of the notification matches exactly to a tab already open it will be focused.
  // "origin" - If the launch URL of the notification matches any tab already open to your domain it will be focused.
  // See addListenerForNotificationOpened documentation for more details.
  // https://documentation.onesignal.com/docs/web-push-sdk#addlistenerfornotificationopened
  public notificationClickHandlerMatch!: string;

  // "navigate" Default: Automatically navigate to the launchURL on opening the notification.
  // "focus" - Only apply if notificationClickHandlerMatch is set to "origin".
  // Only focuses an existing tab if the launch URL matches the domain instead of navigating.
  // See addListenerForNotificationOpened documentation for more details.
  // https://documentation.onesignal.com/docs/web-push-sdk#addlistenerfornotificationopened
  public notificationClickHandlerAction!: string;

  // Special Case on HTTPS ONLY: Absolute path to OneSignal SDK web worker files.
  // You only need to set this parameter if you do not want the files at the root of your site.
  public path!: string;

  public allowLocalhostAsSecureOrigin!: boolean;
}
